.card-location-table-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 100px;
  min-height: 920px;
  width: 1080px;
  z-index: 6;
}

.where-to-find-our-card-title-and-lines {
  align-items: center;
  display: flex;
  gap: 15px;
  margin-left: 1px;
  min-width: 528px;
}

.line-31-stroke-3 {
  height: 3px;
  width: 40px;
}

.where-to-find-our-cards {
  height: 40px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  width: 416px;
}

.card-location-table {
  align-items: flex-start;
  background-color: var(--white);
  border: 0.5px solid;
  border-color: var(--mist-gray);
  border-radius: 10px;
  display: flex;
  height: 850px;
  overflow: hidden;
  width: 100%;
}

.overlap-group1-1 {
  height: 851px;
  margin-top: -1.12px;
  position: relative;
  width: 1080px;
}

.overlap-group-2 {
  height: 851px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1080px;
}

.rectangle-2 {
  background-color: var(--dark-blue);
  height: 23px;
  left: 0;
  opacity: 0.9;
  position: absolute;
  top: 110px;
  width: 1080px;
}

.rectangle-3 {
  background-color: var(--dark-blue);
  height: 23px;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 203px;
  width: 1080px;
}

.rectangle-4 {
  background-color: var(--dark-blue);
  height: 23px;
  left: 0;
  opacity: 0.7;
  position: absolute;
  top: 249px;
  width: 1080px;
}

.rectangle-5 {
  background-color: var(--dark-blue);
  height: 23px;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 295px;
  width: 1080px;
}

.rectangle-6 {
  background-color: var(--dark-blue);
  height: 23px;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 342px;
  width: 1080px;
}

.rectangle-7 {
  background-color: var(--dark-blue);
  height: 23px;
  left: 0;
  opacity: 0.4;
  position: absolute;
  top: 388px;
  width: 1080px;
}

.rectangle-9 {
  background-color: var(--dark-blue);
  height: 23px;
  left: 0;
  opacity: 0.2;
  position: absolute;
  top: 504px;
  width: 1080px;
}

.rectangle-10 {
  background-color: var(--dark-blue);
  height: 23px;
  left: 0;
  opacity: 0.1;
  position: absolute;
  top: 550px;
  width: 1080px;
}

.rectangle-11 {
  background-color: var(--dark-blue);
  height: 255px;
  left: 0;
  opacity: 0.05;
  position: absolute;
  top: 596px;
  width: 1080px;
}

.rectangle-8 {
  background-color: var(--dark-blue);
  height: 47px;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 434px;
  width: 1080px;
}

.frame-363 {
  background-color: var(--dark-blue);
  border-radius: 10px 10px 0px 0px;
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1080px;
}

.line-23-stroke {
  height: 1px;
  left: 0;
  position: absolute;
  top: 40px;
  width: 1080px;
}

.city {
  left: 954px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: right;
  top: 10px;
  white-space: nowrap;
}

.text-4-1 {
  direction: rtl;
  left: 969px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 64px;
  white-space: nowrap;
  width: 75px;
}

.group-146 {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 673px;
  min-height: 807px;
  position: absolute;
  top: 42px;
  width: 217px;
}

.text-5-1 {
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 70px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 69px;
}

.text-2 {
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 70px;
  margin-top: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 63px;
}

.text-7-1 {
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 70px;
  margin-top: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 69px;
}

.text-4 {
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 70px;
  margin-top: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 75px;
}

.text-5 {
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 73px;
  margin-top: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 60px;
}

.text-10 {
  align-self: flex-start;
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 30px;
  margin-top: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 84px;
}

.text-6 {
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 73px;
  margin-top: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 48px;
}

.text-12 {
  align-self: flex-start;
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 9px;
  margin-top: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 126px;
}

.text-7 {
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 70px;
  margin-top: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 57px;
}

.text-15 {
  align-self: flex-start;
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 24px;
  margin-top: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 96px;
}

.text-16 {
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 73px;
  margin-top: 2px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 60px;
}

.text-18 {
  align-self: flex-start;
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 21px;
  margin-top: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 105px;
}

.place-2 {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 33px;
  margin-top: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 78px;
}

.text-21 {
  align-self: flex-start;
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 3px;
  margin-top: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 141px;
}

.text-2-1 {
  align-self: flex-start;
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 15px;
  margin-top: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 114px;
}

.text-26 {
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 73px;
  margin-top: 4px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 60px;
}

.text-27 {
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 70px;
  margin-top: 2px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 69px;
}

.text-29 {
  align-self: flex-start;
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 27px;
  margin-top: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 90px;
}

.text-3-1 {
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 73px;
  margin-top: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 66px;
}

.ua-acadam {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 147px;
}

.text-37 {
  align-self: flex-start;
  direction: rtl;
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 21px;
  margin-top: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 102px;
}

.text-38 {
  direction: rtl;
  left: 948px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 111px;
  white-space: nowrap;
  width: 114px;
}

.text-39 {
  direction: rtl;
  left: 972px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 157px;
  white-space: nowrap;
  width: 69px;
}

.text-40 {
  direction: rtl;
  left: 975px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 204px;
  white-space: nowrap;
  width: 63px;
}

.text-41 {
  direction: rtl;
  left: 960px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 227px;
  white-space: nowrap;
  width: 93px;
}

.text-42 {
  direction: rtl;
  left: 969px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 250px;
  white-space: nowrap;
  width: 72px;
}

.text-43 {
  direction: rtl;
  left: 942px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 273px;
  white-space: nowrap;
  width: 129px;
}

.text-44 {
  direction: rtl;
  left: 954px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 296px;
  white-space: nowrap;
  width: 105px;
}

.text-45 {
  direction: rtl;
  left: 957px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 319px;
  white-space: nowrap;
  width: 96px;
}

.text-46 {
  direction: rtl;
  left: 963px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 343px;
  white-space: nowrap;
  width: 84px;
}

.text-47 {
  direction: rtl;
  left: 972px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 366px;
  white-space: nowrap;
  width: 66px;
}

.text-48 {
  direction: rtl;
  left: 954px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 389px;
  white-space: nowrap;
  width: 105px;
}

.text-49 {
  direction: rtl;
  left: 951px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 446px;
  white-space: nowrap;
  width: 108px;
}

.text-50 {
  direction: rtl;
  left: 963px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 481px;
  white-space: nowrap;
  width: 87px;
}

.text-51 {
  direction: rtl;
  left: 966px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 505px;
  white-space: nowrap;
  width: 78px;
}

.text-53 {
  direction: rtl;
  left: 969px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 551px;
  white-space: nowrap;
  width: 72px;
}

.text-54 {
  direction: rtl;
  left: 960px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 574px;
  white-space: nowrap;
  width: 90px;
}

.text-55 {
  direction: rtl;
  left: 966px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 713px;
  white-space: nowrap;
  width: 81px;
}

.text-56 {
  direction: rtl;
  left: 936px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 412px;
  white-space: nowrap;
  width: 138px;
}

.group-145 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  left: 868px;
  min-height: 807px;
  position: absolute;
  top: 42px;
  width: 109px;
}

.number-6 {
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 13.5px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 12px;
}

.number {
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 10.5px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 18px;
}

.number-1 {
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 9px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 21px;
}

.number-2 {
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 3px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 33px;
}

.number-7 {
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 7.5px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 24px;
}

.number-3 {
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 4.5px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 30px;
}

.number-4 {
  letter-spacing: 0;
  line-height: 18px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 39px;
}

.number-5 {
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 1.5px;
  min-height: 20px;
  text-align: center;
  white-space: nowrap;
  width: 36px;
}

.library {
  left: 722px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: right;
  top: 10px;
  white-space: nowrap;
}

.location {
  left: 398px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: right;
  top: 10px;
  white-space: nowrap;
}

.phone-number-1 {
  left: 58px;
  letter-spacing: 0;
  line-height: 11px;
  position: absolute;
  text-align: center;
  top: 17px;
  white-space: nowrap;
}

.line-23-stroke-1 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 63px;
  width: 918px;
}

.line-23-stroke-2 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 86px;
  width: 918px;
}

.line-23-stroke-3 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 110px;
  width: 1080px;
}

.line-23-stroke-4 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 133px;
  width: 1080px;
}

.line-23-stroke-5 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 156px;
  width: 918px;
}

.line-23-stroke-6 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 179px;
  width: 918px;
}

.line-23-stroke-7 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 202px;
  width: 1080px;
}

.line-23-stroke-8 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 225px;
  width: 1080px;
}

.line-23-stroke-9 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 248px;
  width: 1080px;
}

.line-23-stroke-10 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 272px;
  width: 1080px;
}

.line-23-stroke-11 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 295px;
  width: 1080px;
}

.line-23-stroke-12 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 318px;
  width: 1080px;
}

.line-23-stroke-13 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 341px;
  width: 1080px;
}

.line-23-stroke-14 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 364px;
  width: 1080px;
}

.line-23-stroke-15 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 387px;
  width: 1080px;
}

.line-23-stroke-16 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 410px;
  width: 1080px;
}

.line-23-stroke-17 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 434px;
  width: 1080px;
}

.line-23-stroke-18 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 457px;
  width: 918px;
}

.line-23-stroke-19 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 480px;
  width: 1080px;
}

.line-23-stroke-20 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 503px;
  width: 1080px;
}

.line-23-stroke-21 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 526px;
  width: 1080px;
}

.line-23-stroke-22 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 549px;
  width: 1080px;
}

.line-23-stroke-23 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 573px;
  width: 1080px;
}

.line-23-stroke-24 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 596px;
  width: 1080px;
}

.line-23-stroke-25 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 619px;
  width: 918px;
}

.line-23-stroke-26 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 642px;
  width: 918px;
}

.line-23-stroke-27 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 665px;
  width: 918px;
}

.line-23-stroke-28 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 688px;
  width: 918px;
}

.line-23-stroke-29 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 711px;
  width: 918px;
}

.line-23-stroke-30 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 735px;
  width: 918px;
}

.line-23-stroke-31 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 758px;
  width: 918px;
}

.line-23-stroke-32 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 781px;
  width: 918px;
}

.line-23-stroke-33 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 804px;
  width: 918px;
}

.line-23-stroke-34 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 827px;
  width: 918px;
}

.line-24 {
  height: 850px;
  left: 858px;
  position: absolute;
  top: 1px;
  width: 1px;
}

.line-29 {
  height: 811px;
  left: 918px;
  position: absolute;
  top: 41px;
  width: 1px;
}

.line-26 {
  height: 850px;
  left: 636px;
  position: absolute;
  top: 1px;
  width: 1px;
}

.line-28 {
  height: 850px;
  left: 222px;
  position: absolute;
  top: 1px;
  width: 1px;
}

.text-52 {
  direction: rtl;
  left: 978px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: center;
  top: 528px;
  white-space: nowrap;
  width: 57px;
}
#point-of-sales tbody td {
  text-align: right;
}
#point-of-sales tbody tr:nth-of-type(odd) {
  background-color: #c8e9fa;
}

.point-of-sale-head {
  text-align: center !important;
  background: var(--blue);
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-align: right;
  color: #ffffff;
}
