.bg-image,
.bg-image-1 {
  width: 100%;
  align-items: flex-start;
  display: flex;
  height: 100%;
  left: 0;
  min-width: 1803px;
  opacity: 0.1;
  padding: 0 1.1px;
  position: absolute;
  top: 0;
  z-index: -1;
}

@media only screen and (max-width: 650px) {
  .bg-image,
  .bg-image-1,
  .rm347-porpla-02-a-01-converted-01-01-1 {
    height: 200vh;
  }
}

.rm347-porpla-02-a-01-converted-01-01-1,
.rm347-porpla-02-a-01-converted-01-01-1-1 {
  height: 1142px;
  object-fit: cover;
  width: 600px;
}
.my-learning-page .rm347-porpla-02-a-01-converted-01-01-1,
.my-learning-page .rm347-porpla-02-a-01-converted-01-01-1-1 {
  height: 100%;
  object-fit: cover;
  width: 600px;
}

.rm347-porpla-02-a-01-converted-01-01-2,
.rm347-porpla-02-a-01-converted-01-01-2-1 {
  height: 100%;
  object-fit: cover;
  width: 601px;
}

.rm347-porpla-02-a-01-converted-01-01-3,
.rm347-porpla-02-a-01-converted-01-01-3-1 {
  height: 100%;
  object-fit: cover;
  width: 239px;
}

.quiz-bg-image {
  height: 100%;
  object-fit: cover;
  width: 601px;
}
.quiz-bg-image2 {
  height: 100%;
  object-fit: cover;
  width: 239px;
}

.quiz-main-bg-image {
  width: 100%;
  height: 120% !important;
  align-items: flex-start;
  display: flex;
  left: 0;
  width: 100%;
  opacity: 0.1;
  padding: 0 1.1px;
  position: absolute;
  top: 0;
  z-index: -1;
}
