.cancel-btn {
  align-items: flex-start;
  border: 1.5px solid;
  border-color: var(--blue);
  border-radius: 90px;
  display: flex;
  height: 60px;
  overflow: hidden;
  padding: 10.5px 82.3px;
  width: 255px;
}

.cancel {
  letter-spacing: 0;
  line-height: normal;
  min-height: 36px;
  min-width: 86px;
  text-align: center;
}

.cancel-btn.frame-129 {
  left: 0;
  position: absolute;
  top: 0;
}

