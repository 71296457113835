.group-150 {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  margin-top: 10px;
  min-width: 100px;
}

.t-arrow-1 {
  height: 40px;
  width: 40px;
}

