.country-drop-down {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  right: 43%;
  min-height: 40px;
  position: absolute;
  top: 60px;
  width: 164px;
}

@media only screen and (max-width: 650px) {
  .country-drop-down {
    right: 35%;
  }
  .country-drop-down.language-drop-down {
    right: 2%;
  }
}

.frame-137-1 {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--mist-gray);
  border-radius: 6px;
  display: flex;
  gap: 14px;
  height: 40px;
  overflow: hidden;
  padding: 9.7px 5px;
  width: 164px;
}

.place-3 {
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 38px;
  min-width: 79px;
  text-align: center;
  white-space: nowrap;
}

.frame-3 {
  align-self: center;
  height: 19px;
  margin-bottom: 0;
  width: 23px;
}

.frame-139 {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--mist-gray);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  overflow: hidden;
  width: 164px;
  z-index: 10;
}

.place-4 {
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 19px;
  min-width: 79px;
  text-align: center;
  white-space: nowrap;
}

.place-5 {
  letter-spacing: 0;
  line-height: 18px;
  margin-top: 20px;
  min-width: 42px;
  text-align: center;
  white-space: nowrap;
}

.line-8 {
  align-self: flex-start;
  height: 1px;
  margin-left: -4071px;
  margin-top: 286px;
  width: 148px;
}

.language-drop-down {
  right: 33%;
}

.country-drop-down.language-drop-down .frame-137-1 {
  gap: 19px;
}

.country-drop-down.language-drop-down .place-3 {
  margin-left: 42px;
  min-width: 70px;
}

.country-drop-down.language-drop-down .place-4 {
  min-width: 70px;
}

.country-drop-down.language-drop-down .place-5 {
  min-width: 67px;
}

.country-drop-down.language-drop-down .line-8 {
  margin-left: -4256px;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px #00000020;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #fff;
}
