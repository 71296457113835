.read-more-btn {
  position: absolute;
  bottom: 15%;
  align-items: center;
  align-self: center;
  background-color: var(--blue);
  border-radius: 10px;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  margin-right: 0;
  padding: 4px 10px;
  width: 140px;
}

.read-more {
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.read-more-btn.read-more-btn-1 {
  align-self: unset;
  margin-right: unset;
  width: fit-content;
  position: absolute;
  bottom: 7%;
}
