.we-support-these-countries {
  align-items: center;
  display: flex;
  gap: 15px;
  margin-top: 100px;
  width: fit-content;
  z-index: 11;
}

.line-31-stroke-2 {
  height: 3px;
  min-width: 40px;
}

.we-support-these-countries_2 {
  letter-spacing: 0;
  line-height: 30px;
  margin-top: -1px;
  text-align: center;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .we-support-these-countries_2 {
    line-height: 100%;
  }
}
