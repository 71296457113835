
.three-d-button::before,
.three-d-button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  background: rgba(255, 255, 255, 0.2);
  z-index: -1;
  transition: transform 0.3s;
}

.three-d-button::before {
  top: 0;
  left: 0;
  transform: perspective(10px) rotateX(-5deg);
}

.three-d-button::after {
  bottom: 0;
  left: 0;
  transform: perspective(10px) rotateX(5deg);
}