.grade-image-2,
.grade-image-3 {
  align-items: flex-start;
  background-color: var(--black-2);
  background-image: url(../../static/img/grade-image-14@2x.png);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 190px;
  padding: 38.1px 69px;
  width: 300px;
}

.grade-9-english-2,
.grade-9-english-3 {
  letter-spacing: 0;
  line-height: 45px;
  min-height: 112px;
  text-align: center;
  width: 162px;
}
