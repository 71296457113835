.contact-us-container {
  width: 80%;
  margin-left: 7%;
  padding: 10px;
  margin: 2% 7%;
}

.contact-us-split {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.contact-us-split .contact-us-left-split {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 2%;
}
.contact-us-left-split h1 {
  width: 65%;
  font-weight: 700;
}
.contact-us-left-split p {
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
}
.contact-us-left-split img {
  width: 95%;
}

.contact-us-split .contact-us-right-split {
  padding-top: 20px;
  background: #f3f7fa;
  box-shadow: 0px 4px 14px rgba(48, 79, 254, 0.15);
  border-radius: 10px;
  width: 50%;
  display: flex;
  align-items: center;
}
.contact-us-form {
  width: 100%;
}
.ask-how-we-can-help {
  text-align: center;
  color: #304ffe;
  font-weight: 500;
  font-family: "Poppins";
}
.contact-us-form form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 20px;
}

.contact-us-form form .row {
  width: 80% !important;
}

@media only screen and (max-width: 650px) {
  .title-line-container {
    margin: 5% auto !important;
  }
  .contact-us-split {
    flex-direction: column !important;
  }
  .contact-us-right-split {
    width: 100% !important;
    margin-top: 10%;
  }
  .contact-us-left-split {
    width: 100% !important;
  }
  .contact-us-left-split h1 {
    width: 100%;
    text-align: center;
  }
  .contact-us-container {
    width: 100%;
    margin: 0% !important;
  }
}
