.group-5-1 {
  height: 30px;
  min-width: 540px;
  position: relative;
}

.arrow-down-2-2-1 {
  height: 30px;
  left: 510px;
  position: absolute;
  top: 0;
  width: 30px;
}

