.details-group,
.details-group-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  margin-left: 9.29px;
  margin-top: 6px;
  width: fit-content;
}

.number-of-materials,
.number-of-materials-1 {
  height: 20px;
  margin-right: -2px;
  min-width: 85.57px;
  position: relative;
}

.group-6,
.group-8 {
  height: 11px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 12px;
}

.address-22,
.address-24 {
  left: 22px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;

  top: 0;
  white-space: nowrap;
}

.number-of-videos,
.number-of-videos-1 {
  height: 20px;
  min-width: 79.44px;
  position: relative;
}

.address-23,
.address-25 {
  left: 21px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;

  top: 0;
  white-space: nowrap;
}

.group-7,
.group-9 {
  height: 11px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 11px;
}

.number-of-pdf,
.number-of-pdf-1 {
  height: 20px;
  min-width: 64.44px;
  position: relative;
}

.group-10,
.quiz-icon-1,
.group-11,
.quiz-icon-1-1,
.group-12,
.quiz-icon-1-2,
.group-13,
.quiz-icon-1-3,
.group-14,
.quiz-icon-1-4,
.group-15,
.quiz-icon-1-5,
.group-16,
.quiz-icon-1-6,
.group-17,
.quiz-icon-1-7,
.group-18,
.quiz-icon-1-8,
.group-19,
.quiz-icon-1-9,
.group-20,
.quiz-icon-1-10,
.group-21,
.quiz-icon-1-11,
.group-22,
.quiz-icon-1-12,
.group-23,
.quiz-icon-1-13 {
  height: 11px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 11px;
}

.number-of-quizes,
.number-of-quizes-1 {
  height: 20px;
  min-width: 76.29px;
  position: relative;
}
