.inside-my-learning {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  height: 1340px;
  position: relative;
  width: 1440px;
}

.overlap-group-3 {
  height: 1260px;
  margin-top: 80px;
  position: relative;
  width: 1440px;
  z-index: 1;
}

.units-and-footer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 226px;
  left: 0;
  position: absolute;
  top: 310px;
  width: fit-content;
}

.footer-and-unlock-the-power-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: fit-content;
}

.footer-2 {
  background-color: var(--haiti);
  height: 400px;
  min-width: 1440px;
}

/* .flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 70px;
  min-height: 289px;
  position: relative;
  top: 70px;
  width: 1304px;
} */

.flex-row-5 {
  align-items: flex-start;
  display: flex;
  gap: 163px;
  height: 170px;
  min-width: 1192px;
}

.about-schooltube-in-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 170px;
  width: 422px;
}

.logo-3 {
  height: 40px;
  width: 183px;
}

.lorem-ipsum-dolor-si-4 {
  letter-spacing: 0;
  line-height: 20px;
  width: 420px;
}

.contact-us-section-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  width: 326px;
}

.contact-us-2 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 2.65px;
  min-height: 30px;
}

.phone-number-3 {
  align-items: center;
  display: flex;
  gap: 14px;
  margin-top: 20px;
  min-width: 237px;
  padding: 0 0px;
}

.phone-1-2 {
  height: 19px;
  width: 18px;
}

.address-26 {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 203px;
  white-space: nowrap;
}

.email-2 {
  align-items: center;
  display: flex;
  gap: 15px;
  margin-left: 0.5px;
  margin-top: 10px;
  min-width: 326px;
  padding: 0 0px;
}

.letter-1-2 {
  height: 16px;
  width: 17px;
}

.schooltubeonlinehotmailcom-2 {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 292px;
  white-space: nowrap;
}

.follow-us-section-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 70px;
  width: 117px;
}

.follow-us-2 {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 2px;
  min-height: 30px;
  min-width: 115px;
}

.social-media-icons-2 {
  height: 30px;
  margin-right: 2px;
  width: 80px;
}

.scroll-top-btn-2 {
  align-self: flex-end;
  cursor: pointer;
  height: 58px;
  margin-top: 6px;
  width: 58px;
}

.line-1-2 {
  height: 1px;
  margin-top: 15px;
  object-fit: cover;
  width: 1300px;
}

.flex-row-6 {
  align-items: flex-start;
  display: flex;
  gap: 782px;
  margin-top: 19px;
  min-width: 1300px;
}

.copyright-2022-schoo-2 {
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  width: 356px;
}

.powered-by-brainkets-2 {
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  width: 162px;
}

.inside-grade-top-banner {
  align-items: flex-start;
  background-color: var(--black-4);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 280px;
  left: 0;
  padding: 49px 180px;
  position: absolute;
  top: 0;
  width: 1440px;
}

.grade-9-english-1 {
  height: 40px;
  letter-spacing: 0;
  line-height: 18px;
  width: 356px;
}

.material-btn-container {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  margin-top: 31px;
  min-width: 349px;
}

.chemisty-material-btn {
  align-items: center;
  background-color: var(--blue);
  border-radius: 7.5px;
  display: flex;
  gap: 15px;
  height: 30px;
  justify-content: center;
  padding: 4.5px 15px;
  width: fit-content;
}

.chemistry-1,
.math-1,
.physics-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -0.5px;
  margin-top: -3.5px;
  width: fit-content;
}

.math-material-btn {
  align-items: center;
  background-color: var(--white);
  border-radius: 7.5px;
  display: flex;
  gap: 15px;
  height: 30px;
  justify-content: center;
  padding: 4.5px 15px;
  width: fit-content;
}

.physics-material-btn {
  align-items: center;
  background-color: var(--mercury);
  border-radius: 7.5px;
  display: flex;
  gap: 15px;
  height: 30px;
  justify-content: center;
  padding: 4.5px 15px;
  width: fit-content;
}

.material-btn-container-1 {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  height: 30px;
  margin-top: 20px;
  min-width: 220px;
  position: relative;
}

.grade-details {
  align-items: flex-start;
  background-color: var(--white);
  box-shadow: 0px 0px 10px #00000040;
  display: flex;
  flex-direction: column;
  height: 450px;
  left: 960px;
  position: absolute;
  top: 110px;
  width: 300px;
}

.grade-8-english-title {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 20px;
  margin-top: 19px;
  white-space: nowrap;
}

.nav-bar-2 {
  align-items: center;
  background-color: var(--white);
  box-shadow: 0px 2px 6px #0000001a;
  display: flex;
  height: 80px;
  left: 0;
  padding: 0 70px;
  position: fixed;
  top: 0;
  width: 1440px;
  z-index: 2;
}

.logo-4 {
  cursor: pointer;
  height: 40px;
  width: 183px;
}
