.home-loading {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.home-page {
  align-items: center;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.nav-bar {
  align-items: center;
  background-color: var(--white);
  box-shadow: 0px 2px 6px #0000001a;
  display: flex;
  height: 80px;
  left: 0;
  padding: 1%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 13;
}

.logo {
  height: 40px;
  width: 183px;
}

.nav-bar-titles {
  flex-basis: 100%;
  float: right;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  gap: 2%;
  margin-right: 10px;
  position: relative;
  right: 0;
}

.students-feedback-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* margin-top: 120px; */
  margin-top: 25px;
  min-height: 360px;
  position: relative;
  width: 90%;
  z-index: 3;
}
.feeds-blent {
  background-color: #000;
  background-blend-mode: multiply;
}
.supported-grades-section {
  align-items: flex-start;
  /* background-color: var(--haiti); */
  background-color: #eaedff;
  display: flex;
  /* height: 630px; */
  /* margin-top: 120px; */
  margin-top: 40px;
  width: 100%;
  z-index: 4;
}

.overlap-group1 {
  align-items: center;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  /* min-height: 650px; */
  padding: 71px 0;
  position: relative;
  width: 100%;
}

.all-grades-small-title {
  align-self: flex-start;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;

  left: 10%;
  min-width: 84px;
  text-align: right;
  white-space: nowrap;
}

.supported-grades-container {
  height: 530px;
  margin-top: 11px;
  position: relative;
  width: 100%;
}

.supported-grades-title {
  color: var(--black);
  /* color: var(--white); */
  font-family: var(--font-family-poppins);
  font-size: 35px;
  font-weight: 700;
  height: 20px;
  left: 10%;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: right;
  top: 19px;
  white-space: nowrap;
}

.visit-all-grades-btn-section {
  width: 10%;
  margin-top: 3%;
  margin-left: 2%;
  position: relative;
  bottom: 7%;
}

.flag-container {
  align-items: flex-start;
  display: flex;
  gap: 198px;
  height: 180px;
  margin-left: 2px;
  margin-top: 40px;

  z-index: 12;
}
@media only screen and (max-width: 450px) {
  .supported-grades-container {
    height: 420px !important;
  }
}
@media only screen and (max-width: 600px) {
  .supported-grades-container {
    height: 480px;
  }
  .overlap-group1 {
    padding: 30px 0;
  }
  .home-loading img {
    width: 70% !important;
  }
  .flag-container {
    flex-direction: column;
    height: 150px;
    gap: 30px;
  }
  .step-1 {
    width: 100%;
  }
  #point-of-sales {
    width: 100% !important;
    /* padding: 30% 15px; */
    padding: 10% 15px;
  }
  .supported-grades-title {
    font-size: 25px  }
  .footer {
    margin-bottom: 75px;
  }

  .x-flag-1 {
    width: 115px !important;
  }
}

.x-flag {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-height: 180px;
  width: 145px;
}

.x-flag-1 {
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 10px;
  height: 90px;
  margin-right: 2px;
  width: 135px;
}

.place {
  height: 20px;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 2px;
  text-align: center;
  white-space: nowrap;
  width: 125px;
}

.place-1 {
  height: 20px;
  letter-spacing: 0;
  line-height: 18px;
  margin-right: 2px;
  text-align: center;
  white-space: nowrap;
  width: 61px;
}

.x962 {
  /* margin-top: 190px; */
  margin-top: 0px;
  object-fit: cover;
  width: 100%;
  z-index: 10;
}

.footer {
  align-items: center;
  /* background-color: var(--haiti); */
  background-color: #d4e4fe;
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 41px 66px;
  width: 100%;
  z-index: 7;
}

.flex-row {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  gap: 163px;
  height: 80%;
  margin-left: 4px;
  margin-top: 29px;
  min-width: 1192px;
}

@media only screen and (max-width: 600px) {
  .flex-row {
    flex-direction: column;
    gap: 70px;
  }
  .flex-row-1 {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    gap: 10px;
    margin-top: 19px;
    width: 100%;
  }
}

.lorem-ipsum-dolor-si {
  letter-spacing: 0;
  line-height: 20px;
  width: 420px;
}

.scroll-top-btn {
  align-self: flex-end;
  cursor: pointer;
  height: 58px;
  margin-top: 6px;
  width: 58px;
}

.line-1 {
  height: 1px;
  margin-top: 15px;
  object-fit: cover;
  width: 1300px;
}

.flex-row-1 {
  align-items: flex-start;
  display: flex;
  gap: 50%;
  margin-left: 15%;
  margin-top: 19px;
  width: 90%;
}

#point-of-sales {
  width: 60%;
  margin: 5% auto;
}
