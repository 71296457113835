@import url("https://fonts.googleapis.com/css2?family=Abel&family=Baloo+Da+2&family=Grape+Nuts&family=Montserrat&family=Poppins:wght@400;600&family=Roboto:wght@500&display=swap");

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
}

:root {
  --mainColor: #fff;
  --mainColorLight: #ccc;
  --secondaryColor: #db2b39;
  --textColor: #304ffe;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  padding: 0 1.2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
  z-index: 50 !important;
}

nav a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
  white-space: nowrap;
  /* font-size: 0.8rem; */
}

nav a:hover {
  color: var(--secondaryColor);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
  width: 20px;
}

header div,
nav {
  display: flex !important;
  align-items: center;
}
.nav-list {
  display: flex !important;
  flex-direction: row !important;
}

.nav-list .go-to-old-version {
  /* font-weight: 600; */
  cursor: pointer;
}

.nav-list .go-to-old-version:hover {
  color: var(--secondaryColor);
}

@media only screen and (max-width: 650px) {
  .header {
    padding: none !important;
  }
}
@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
    position: absolute;
    z-index: 100;
    top: 10px;
    right: 5%;
    width: fit-content;
    display: none;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 0.3s;
    transform: translateY(-150vh);
    z-index: 999;
  }

  header .responsive_nav {
    transform: none;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
  .navbar-logo {
    padding: 20px 20px 20px 0;
  }
  .search-text {
    line-height: 0 !important;
  }
  .search-btn {
    right: 10px !important;
  }
}

/*Nav logo*/
.navbar-logo {
  height: 100%;
  padding: 20px;
}
.navbar-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/*Search Bar starts Here*/

.search {
  width: 170px;
  height: 30px;
  border-radius: 40px;
  padding: 10px;
  background: #fff;
  border: 1px solid #ccc;
  position: relative;
}
.search input {
  font-size: 0.8rem;
}
.search-btn {
  border-radius: 50%;
  background: transparent;
  color: #000;
  float: right;
  /* width: 40px; */
  /* height: 40px; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: absolute;
  right: 0;
}
.search-text {
  width: 140px;
  border: none;
  background: none;
  outline: none;
  float: left;
  padding: 0;
  color: #000;
  font-size: 16px;
  transition: 0.6s;
  line-height: 40px;
  padding-left: 15px;
}

/*user profile*/
.user-login-profile {
  width: 40px;
  height: 40px;
  border: 2px solid #304ffe;
  border-radius: 50%;
}
.user-login-profile img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.mobile-login-nav {
  position: absolute;
  right: 8%;
}

@media only screen and (max-width: 600px) {
  .user-login-profile {
    width: 30px;
    height: 30px;
    border: 2px solid #304ffe;
    border-radius: 50%;
  }
}
