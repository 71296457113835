.quizes {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  height: 1370px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.overlap-group-11 {
  height: 1290px;
  margin-left: -1px;
  margin-top: 80px;
  position: relative;
  width: 1803px;
  z-index: 1;
}

.footer-8 {
  align-items: center;
  background-color: var(--haiti);
  display: flex;
  flex-direction: column;
  height: 400px;
  left: 1px;
  padding: 41px 66px;
  position: absolute;
  top: 890px;
  width: 1440px;
}

.flex-row-20 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  gap: 163px;
  height: 170px;
  margin-left: 4px;
  margin-top: 29px;
  min-width: 1192px;
}

.about-schooltube-in-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 170px;
  width: 422px;
}

.logo-16 {
  height: 40px;
  width: 183px;
}

.lorem-ipsum-dolor-si-9 {
  letter-spacing: 0;
  line-height: 20px;
  width: 420px;
}

.contact-us-section-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  width: 326px;
}

.contact-us-8 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 2.65px;
  min-height: 30px;
}

.phone-number-8 {
  align-items: center;
  display: flex;
  gap: 14px;
  margin-top: 20px;
  min-width: 237px;
  padding: 0 0px;
}

.phone-1-8 {
  height: 19px;
  width: 18px;
}

.address-23 {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 203px;
  white-space: nowrap;
}

.email-8 {
  align-items: center;
  display: flex;
  gap: 15px;
  margin-left: 0.5px;
  margin-top: 10px;
  min-width: 326px;
  padding: 0 0px;
}

.letter-1-8 {
  height: 16px;
  width: 17px;
}

.schooltubeonlinehotmailcom-8 {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 292px;
  white-space: nowrap;
}

.follow-us-section-8 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 70px;
  width: 117px;
}

.follow-us-8 {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 2px;
  min-height: 30px;
  min-width: 115px;
}

.social-media-icons-8 {
  height: 30px;
  margin-right: 2px;
  width: 80px;
}

.scroll-top-btn-8 {
  align-self: flex-end;
  cursor: pointer;
  height: 58px;
  margin-top: 6px;
  width: 58px;
}

.line-1-9 {
  height: 1px;
  margin-top: 15px;
  object-fit: cover;
  width: 1300px;
}

.flex-row-21 {
  align-items: flex-start;
  display: flex;
  gap: 782px;
  margin-top: 19px;
  min-width: 1300px;
}

.copyright-2022-schoo-8 {
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  width: 356px;
}

.powered-by-brainkets-8 {
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  width: 162px;
}

.quizes-1 {
  height: 30px;
  left: 181px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: 49px;
  width: 85px;
}

.title-lines-4 {
  align-items: flex-start;
  background-image: url(../../static/img/line-32@1x.svg);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 1px;
  left: 181px;
  min-width: 1080px;
  position: absolute;
  top: 93px;
}

.line-33-4 {
  height: 3px;
  margin-top: -3px;
  width: 85px;
}

.question-1-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 181px;
  min-height: 200px;
  position: absolute;
  top: 124px;
  width: 310px;
}

.question-2-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 821px;
  min-height: 200px;
  position: absolute;
  top: 124px;
  width: 314px;
}

.question-3-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 181px;
  min-height: 200px;
  position: absolute;
  top: 374px;
  width: 406px;
}

.question-4-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 821px;
  min-height: 200px;
  position: absolute;
  top: 374px;
  width: 407px;
}

.nav-bar-8 {
  align-items: center;
  background-color: var(--white);
  box-shadow: 0px 2px 6px #0000001a;
  display: flex;
  height: 80px;
  left: 0;
  padding: 0 70px;
  position: fixed;
  top: 0;
  width: 1440px;
  z-index: 2;
}

.logo-17 {
  cursor: pointer;
  height: 40px;
  width: 183px;
}

.quiz-bg-image {
  height: 100%;
}
.quiz-bg-image * {
  height: 100%;
}
.quiz-container {
  display: flex;
  flex-direction: column;
}
.quiz-result-container {
  width: 100%;
  padding: 20px;
  background: linear-gradient(180deg, #007cff 0%, #304ffe 100%);
  border-radius: 20px;
  display: none;
  flex-direction: row;
}
.quiz-questions-container {
  display: flex;
  /* new */
  flex-direction: column;
  width: 80%;
  /* flex-wrap: wrap; */
  margin: 2% auto 2% auto;

  gap: 10%;
  justify-content: space-between;
}
.quiz-section-container {
  width: 40%;
  height: 50%;
  padding: 10px;
  margin-top: 20px;
}
.quiz-section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.quiz-section-header .quiz-section-number {
  width: 30px;
  min-width: 30px;
  background-color: #304ffe;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}
.quiz-section-header .quiz-section-title {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 20px;
}

.quiz-section-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
  margin-left: 10%;
}

.quiz-section-content .answer {
  width: fit-content;
  height: 20%;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
}
.answer input {
  width: 0%;
  height: 45px;
  margin-right: 5px;
  border-radius: 60px;
}

.answer-text {
  margin-top: 5px;
  cursor: pointer;
}

.quiz-submit-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5% auto;
}
.quiz-submit-btn {
  width: 30%;
  height: 50px;
  border-radius: 2rem;
}

/*style the radio*/

/* Hide the default checkbox */
.quiz-form-container input[type="radio"] {
  visibility: hidden;
}

/* Creating a custom checkbox
based on demand */
.answer-radio {
  position: absolute;
  top: 10px;
  left: 20px;
  height: 25px;
  width: 25px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: white;
}

/* Specify the background color to be
shown when hovering over checkbox */
.answer:hover input ~ .answer-radio {
  background-color: #ccc;
}

/* Specify the background color to be
shown when checkbox is active */
/* .answer input:active ~ .answer-check {
  background-color: red;
} */

/* Specify the background color to be
shown when checkbox is checked */
.answer input:checked ~ .answer-radio {
  background-color: #304ffe;
}
.answer input:checked[value="success"] ~ .answer-radio {
  background-color: green;
}
.answer input:checked[value="failed"] ~ .answer-radio {
  background-color: red;
}

/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.answer-radio:after {
  content: "";
  position: absolute;
  display: none;
}

/* Display checkmark when checked */
.answer input:checked ~ .answer-radio:after {
  display: block;
}

/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and 
showing only two border to make it look
like a tickmark */
.answer .answer-radio:after {
  left: 8px;
  bottom: 5px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*==========style the checkmark===============*/
.answer {
  display: block;
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  font-size: 20px;
}

/* Hide the default checkbox */
.answer input[type="checkbox"] {
  visibility: hidden;
}

/* Creating a custom checkbox
based on demand */
.answer-check {
  position: absolute;
  top: 10px;
  left: 20px;
  height: 25px;
  width: 25px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
}

/* Specify the background color to be
shown when hovering over checkbox */
.answer:hover input ~ .answer-check {
  background-color: #ccc;
}

/* Specify the background color to be
shown when checkbox is active */
/* .answer input:active ~ .answer-check {
  background-color: red;
} */

/* Specify the background color to be
shown when checkbox is checked */
.answer input:checked ~ .answer-check {
  background-color: #304ffe;
}
.answer input:checked[value="success"] ~ .answer-check {
  background-color: green;
}
.answer input:checked[value="failed"] ~ .answer-check {
  background-color: red;
}

/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.answer-check:after {
  content: "";
  position: absolute;
  display: none;
}

/* Display checkmark when checked */
.answer input:checked ~ .answer-check:after {
  display: block;
}

/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and 
showing only two border to make it look
like a tickmark */
.answer .answer-check:after {
  left: 8px;
  bottom: 5px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.quiz-progress-bar-container {
  width: 100%;
  position: relative;
  margin: auto 20px;
}
.quiz-progress-bar {
  width: 100%;
  background-color: #ccc;

  border-radius: 20px;
}
.quiz-progress-bar-active {
  width: 90%;
  height: 100%;
  background-color: #ffb301;
  border: 8px solid transparent;
  border-radius: 20px;
}

.progress-title {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: 10px;
}

.quiz-mark {
  color: #fff;
  font-size: 1.75rem;
  font-weight: 400;
  position: absolute;
  right: 10px;
  top: -10px;
  padding-bottom: 10px;
}
.quiz-mark-details {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  position: absolute;
  right: 10px;
  top: 50px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 0;
  line-height: 15px;
  align-items: center;
}

/**for responsivity */
@media only screen and (max-width: 650px) {
  .quiz-questions-container {
    flex-direction: column;
  }
  .quiz-section-container {
    width: 80%;
  }
  .quiz-submit-btn {
    width: fit-content !important;
  }
  .quiz-result-container {
    margin-top: 20px;
    align-items: center;
  }
  .quiz-result-container img {
    width: 15% !important;
  }
  .quiz-progress-bar-container {
    padding-bottom: 25px;
  }
}
