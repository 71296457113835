.my-learnin-course-6-card-wrapper {
  overflow: hidden;
  border-radius: 10px;
}
.my-learnin-course-6-card {
  align-items: flex-start;
  background-color: var(--white);
  /* box-shadow: 0px 0px 8px #00000040; */
  display: flex;
  flex-direction: column;
  height: 307px;
  left: 181px;
  overflow: hidden;
  top: 410px;
  width: 250px;

  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(45deg, #304ffe, #e42129) border-box;
  border: 2px solid transparent;
  border-radius: 15px;
  box-shadow: 0 0 11.94px #00000033;
  /* border: 4px solid transparent;
  border-image: linear-gradient(to right, #4444ff, #ff3d3d) 1;
  border-radius: 5px; */
}

.grade-3 {
  font-family: "Poppins";
  letter-spacing: 0;
  line-height: 20px !important;
  margin-left: 9.29px;
  /* font-size: 18px; */
  margin-top: 9px;
  font-weight: 600;
  font-style: normal;
}

.my-learnin-course-6-card.my-learnin-course-6-card-1,
.my-learnin-course-6-card.my-learnin-course-6-card-3 {
  left: 401px;
}

.my-learnin-course-6-card.my-learnin-course-5-card,
.my-learnin-course-6-card.my-learnin-course-5-card-1 {
  left: 1061px;
  top: 140px;
}

.my-learnin-course-6-card.my-learnin-course-4-card,
.my-learnin-course-6-card.my-learnin-course-4-card-1 {
  left: 841px;
  top: 140px;
}

.my-learnin-course-6-card.my-learnin-course-3-card,
.my-learnin-course-6-card.my-learnin-course-3-card-1 {
  left: 621px;
  top: 140px;
}

.my-learnin-course-6-card.my-learnin-course-2-card,
.my-learnin-course-6-card.my-learnin-course-2-card-1 {
  left: 401px;
  top: 140px;
}

@media (max-width: 600px) {
  .my-learnin-course-6-card {
    align-items: flex-start;
    background-color: var(--white);
    /* box-shadow: 0px 0px 8px #00000040; */
    display: flex;
    flex-direction: column;
    height: 238px;
    left: 181px;
    overflow: hidden;
    top: 410px;
    width: 250px;

    background: linear-gradient(#fff, #fff) padding-box,
      linear-gradient(45deg, #304ffe, #e42129) border-box;
    border: 2px solid transparent;
    border-radius: 15px;
    box-shadow: 0 0 11.94px #00000033;
    /* border: 4px solid transparent;
  border-image: linear-gradient(to right, #4444ff, #ff3d3d) 1;
  border-radius: 5px; */
  }
}
