/*here is my footer*/
html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
}
.footer {
  background-color: #d4e4fe;
  padding: 41px 66px;
  width: 100%;
}

.footer-flex-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .footer-flex-row {
    flex-direction: column;
    gap: 70px;
  }
  .footer-flex-row-1 {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
    gap: 10px;
    margin-top: 19px;
    width: 100%;
  }
  .about-schooltube-in {
    width: 100% !important;
  }
}

.about-schooltube-in {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 35%;
}

.footer-logo-image {
  height: 60px;
  object-fit: contain;
}
.lorem-ipsum-dolor-si {
  letter-spacing: 0;
  line-height: 20px;
  width: 420px;
}

.contact-us-section {
  display: flex;
  gap: 5px;
  flex-direction: column;
  width: 30% !important;
  white-space: nowrap;
  color: black;
}

.contact-us {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 2.65px;
  min-height: 30px;
}

.phone-number {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 14px;
  margin-top: 20px;
  min-width: 237px;
}
.phone-number-item {
  display: flex;
  width: fit-content;
  gap: 5px;
  /* width: 40%; */
  width: 45%;
}

.phone-1 {
  height: 19px;
  width: 18px;
}

.address {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 203px;
  white-space: nowrap;
}

.email {
  align-items: center;
  display: flex;
  gap: 15px;
  margin-left: 0.5px;
  margin-top: 10px;
  min-width: 326px;
}

.letter-1 {
  height: 16px;
  width: 17px;
}

.schooltubeonlinehotmailcom {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 292px;
  white-space: nowrap;
}

.follow-us-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 70px;
}
.follow-us-links {
  display: flex;
  gap: 10px;
}

.follow-us {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 2px;
  min-height: 30px;
  min-width: 115px;
}

.social-media-icons {
  height: 30px;
  margin-right: 2px;
  width: 80px;
}

.scroll-top-btn {
  align-self: flex-end;
  cursor: pointer;
  height: 58px;
  margin-top: 6px;
  width: 58px;
}

.line-1 {
  height: 1px;
  margin-top: 15px;
  object-fit: cover;
  width: 1300px;
}
.footer-last-line {
  width: 80%;
  height: 3px;
  border-radius: 20px;
  background-color: #ffffff;
}

.footer-flex-row-1 {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.copyright-2022-schoo {
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  width: fit-content;
  white-space: nowrap;
}

.powered-by-brainkets {
  height: 25px;
  letter-spacing: 0;
  line-height: normal;
  width: 130px;
  white-space: nowrap;

  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.powered-by-brainkets img {
  width: 100%;
  height: 100%;
  transition: ease-in-out 0.3s;
}
.powered-by-brainkets a {
  text-decoration: none;
  color: #fff;
  width: 100%;
  height: 100%;
  transition: ease-in-out 0.3s;
}
.powered-by-brainkets:hover {
  /* background: #000; */
  -ms-transform: scale(0.95); /* IE 9 */
  transform: scale(0.95);
}

/* -------------------responsivity-------------------- */
@media only screen and (max-width: 900px) {
  .footer-flex-row {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 650px) {
  .footer {
    padding: 10px !important;
  }
  .follow-us-section {
    width: 40% !important;
  }
  .copyright-2022-schoo {
    white-space: normal;
  }
  .x962 {
    display: none;
  }
  .phone-number {
    flex-direction: column;
    align-items: flex-start;
  }
}
