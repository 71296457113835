.supported-grades-card-image {
  align-items: center;
  /* background-color: var(--black-2); */
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 10px 10px 10px 10px;
  display: flex;
  z-index: 10;
  overflow: hidden;
  height: 290px;
  width: 100%;
}

.grade-2 {
  letter-spacing: 0;
  line-height: 45px;
  min-height: 97px;
  text-align: center;
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  white-space: pre-wrap;
}
.cardSpanText {
  width: 300px;
}
