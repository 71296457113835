.nav-profile-image {
  width: 60px;
  height: 60px;
}

@media only screen and (max-width: 650px) {
  .nav-profile-image {
    width: 40px;
    height: 40px;
  }
}
