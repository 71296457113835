.teacher-card-1 {
  align-items: flex-start;
  background-size: cover; /* Ensures the image covers the entire background */
  background-position: center; /* Centers the image */
  border-radius: 12px;
  box-shadow: 0px 0px 8px #00000040;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 350px;
  position: relative;
  overflow: hidden;
  justify-content: flex-end;
}

.teacher-card-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  color: #fff;
  text-align: center;
}

.teacher-card-top-circle {
  position: absolute;
  width: 50%;
  height: 30%;
  left: -10%;
  top: 50%;
  border-radius: 50%;
  background: #304ffe;
  mix-blend-mode: screen;
  opacity: 0.6;
}
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.teacher-card-bot-circle {
  position: absolute;
  width: 100%;
  height: 70%;
  right: -25%;
  bottom: -10%;
  border-radius: 50%;
  background: #304ffe;
  mix-blend-mode: screen;
  opacity: 0.6;
}
.teacher-card-1::before, .teacher-card-1::after {
  content: '';
  position: absolute;
  background-color: red;
  height: 10px; /* Adjust thickness of the lines */
}

.teacher-card-1::before {
  width: 150px; /* Adjust length of the line */
  transform: rotate(45deg);
  top: 10px; /* Adjust position */
  right: -40px; /* Adjust position */
}

.teacher-card-1::after {
  width: 120px; /* Adjust length of the line */
  transform: rotate(45deg);
  top: 30px; /* Adjust position */
  right: -20px; 
  z-index: 20;
}

.teachers-cards-container .teacher-card-image {
  position: relative;
  height: 90%;
  width:  80%;
  bottom: 0;
  left: 20%;
  z-index: 10;
  top:25%;
  object-fit: contain  !important;
}

.teacher-card-overlay {
  position: absolute;
  top: 35%;
  left: 10px;
  z-index: 20;
  width: 50px;
  height: 50px;
}


.teacher-card-text-content {
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0 0 12px 12px;
  width: 100%;
}
