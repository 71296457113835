@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600");
/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url("https://px.animaapp.com/6266a61fa0aef730b1d7c23b.6266a621de68953c78e2f85b.V0ZZ16m.hcp.png");

.screen a {
  display: contents;
  text-decoration: none;
}

/* .container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
} */

.auto-animated div {
  --z-index: -1;
  opacity: 0;
  position: absolute;
}

.auto-animated input {
  --z-index: -1;
  opacity: 0;
  position: absolute;
}

.auto-animated .container-center-vertical,
.auto-animated .container-center-horizontal {
  opacity: 1;
}

.overlay-base {
  display: none;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.overlay-base.animate-appear {
  align-items: center;
  animation: reveal 0.3s ease-in-out 1 normal forwards;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
}

.overlay-base.animate-disappear {
  animation: reveal 0.3s ease-in-out 1 reverse forwards;
  display: block;
  opacity: 1;
  pointer-events: none;
}

.overlay-base.animate-disappear * {
  pointer-events: none;
}

@keyframes reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.listeners-active,
.listeners-active * {
  pointer-events: auto;
}

.hidden,
.hidden * {
  pointer-events: none;
  visibility: hidden;
}

.listeners-active-click,
.listeners-active-click * {
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

.logo {
  height: 40px;
  width: 183px;
}

.nav-bar-titles {
  align-items: center;
  display: flex;
  gap: 20px;
  margin-left: 43px;
  width: fit-content;
}

.my-learning-title-1 {
  min-width: 74px;
}

.about-1 {
  left: 3px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.about-2 {
  left: 3px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.about-3 {
  left: 3px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.about-4 {
  left: 3px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.my-learning-title-2 {
  min-width: 99px;
}

.all-grades-title {
  height: 20px;
  min-width: 87px;
  position: relative;
}

.point-of-sale-title-1 {
  min-width: 115px;
}

.point-of-sale-title-2 {
  min-width: 72px;
}

.contact-us-title {
  height: 20px;
  min-width: 97px;
  position: relative;
}

.search-field {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--mercury);
  border-radius: 60px;
  display: flex;
  gap: 112px;
  height: 40px;
  margin-left: 43px;
  min-width: 200px;
  padding: 11.5px 10px;
}

.search {
  color: var(--mercury);
  font-family: var(--font-family-inter-semibold);
  font-size: var(--font-size-xxxxs);
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  min-height: 15px;
  min-width: 52px;
}

.icon-search {
  align-self: center;
  height: 16px;
  margin-top: 0;
  width: 16px;
}

.language {
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-left: 30px;
  width: fit-content;
}

.group-1 {
  height: 29px;
  margin-bottom: -0.5px;
  margin-left: -0.5px;
  margin-top: -0.5px;
  min-width: 29px;
}

.en {
  letter-spacing: 0;
  line-height: normal;
  width: fit-content;
}

.group-2 {
  height: 18px;
  margin-right: -0.5px;
  min-width: 10.14px;
}

.login-btn {
  align-items: center;
  border: 1px solid;
  border-color: var(--blue);
  border-radius: 30px;
  display: flex;
  gap: 10px;
  height: 22px;
  justify-content: center;
  /* margin-left: 30px; */
  padding: 15px;
  width: fit-content;
  font-weight: 650 !important;
  font-family: "Roboto";
}

.login {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -6px;
  margin-top: -8px;
  width: fit-content;
}

/* .group-148 {
  align-self: flex-end;
  height: 82px;
  margin-left: 10px;
  width: 78px;
}

.unlock-the-power-of-your-study {
  color: var(--blue);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-weight: 600;
  height: 40px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 2px;
  margin-left: 192px;
  width: 472px;
}

.login-read-more-btn {
  align-items: center;
  background-color: var(--blue);
  border-radius: 10px;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  margin-left: 216px;
  padding: 4px 10px;
  width: fit-content;
}

.group-147 {
  align-self: flex-end;
  height: 95px;
  margin-left: 198px;
  width: 82px;
}

/* .login-flex-row-2 {
  align-self: flex-start;
  gap: 163px;
  height: 170px;
  margin-left: 4px;
  margin-top: 29px;
  min-width: 1192px;
} 

.about-schooltube-in {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 170px;
  width: 422px;
}

.contact-us-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  width: 326px;
}

.phone-1 {
  height: 19px;
  width: 18px;
}

.address {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 203px;
  white-space: nowrap;
}

.email {
  align-items: center;
  display: flex;
  gap: 15px;
  margin-left: 0.5px;
  margin-top: 10px;
  min-width: 326px;
}

.letter-1 {
  height: 16px;
  width: 17px;
}

.schooltubeonlinehotmailcom {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 292px;
  white-space: nowrap;
}

.follow-us-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 70px;
  width: 117px;
}

.follow-us {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 2px;
  min-height: 30px;
  min-width: 115px;
}

.social-media-icons {
  height: 30px;
  margin-right: 2px;
  width: 80px;
}

.scroll-top-btn {
  align-self: flex-end;
  height: 58px;
  margin-top: 6px;
  width: 58px;
}

.line-1 {
  height: 1px;
  margin-top: 15px;
  object-fit: cover;
  width: 1300px;
}

.flex-row-3 {
  gap: 782px;
  margin-top: 19px;
  min-width: 1300px;
}

.copyright-2022-schoo {
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  width: 356px;
}

.powered-by-brainkets {
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  width: 162px;
}
*/
.flex-row {
  align-items: flex-start;
  display: flex;
}

.my-learning-title {
  height: 20px;
  position: relative;
}

.point-of-sale-title {
  height: 20px;
  position: relative;
}

/* .modal-dialog {
  max-width: 60%;
} */

.form-container {
  width: 80% !important;
}

.bold-text {
  font-weight: 600;
  font-size: 1.05rem;
}

@media only screen and (max-width: 650px) {
  .modal-body .row:first-child {
    flex-direction: column-reverse;
  }
  /* .row > * {
    padding-right: 5px !important;
    padding-left: 0px !important;
  } */
  .dropdown-toggle::after {
    display: none !important;
  }
  .welcome-back {
    margin-top: 0 !important;
  }
}
