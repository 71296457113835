/* .language {
  align-items: center;
  display: flex;
  gap: 5px;
  justify-content: center;
  margin-left: 30px;
  width: fit-content;
  cursor: pointer;
  position: relative;
} */

/* .lang-dropdown-items {
  z-index: 10;
  width: 120px;
  height: 200px;
  background-color: white;
  position: absolute;
  top: 40px;
  border: 1px solid var(--blue);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
} */

.group {
  height: 29px;
  margin-bottom: -0.5px;
  margin-left: -0.5px;
  margin-top: -0.5px;
  min-width: 29px;
}

.en {
  letter-spacing: 0;
  line-height: normal;
  width: fit-content;
}

.group-1 {
  height: 5px;
  margin-right: -0.5px;
  min-width: 10.14px;
}

.language.language-1 .group,
.language.language-2 .group,
.language.language-3 .group,
.language.language-4 .group {
  min-width: 29px;
}

.language.language-1 .group-1,
.language.language-2 .group-1 {
  min-width: 10.14px;
}

.language.language-3 .group-1,
.language.language-4 .group-1 {
  min-width: 10.14px;
}

/* .dropdown-item1
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 100% !important;
} */
/* 
nav .MuiSvgIcon-root {
  display: none;
} */
nav
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px;
}
nav .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  left: -5px !important;
  font-size: 0.8rem;
}
nav .css-qiwgdb.css-qiwgdb.css-qiwgdb {
  padding-right: 10px;
}

nav .css-qiwgdb {
  padding: 10px;
}

/* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  width: 35px !important;
} */

.language-form-control-dropdown .css-1vynybe.css-1vynybe.css-1vynybe {
  padding: 5px 20px 10px 5px !important;
}

.language-form-control-dropdown svg {
  /* display: none; */
  margin-left: 5px;
}
.language-form-control-dropdown .css-zrqpq1::before,
.css-zrqpq1::after {
  border-bottom: 1px solid transparent !important;
}
