.grade-image,
.grade-image-1 {
  align-items: flex-start;
  background-color: var(--black-2);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 55%;
  overflow: hidden;
  padding: 28.3px 45.8px;
  width: 100%;
  position: relative;
}
.grade-blur-img {
  align-items: flex-start;
  background-color: var(--black-2);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 10px;
  display: flex;
  height: 55%;
  overflow: hidden;
  padding: 28.3px 45.8px;
  width: 100%;
  position: relative;
}
.hidden {
  display: none;
}
.grade-5 {
  height: 50px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  width: 50%;
  position: absolute;
  top: 30%;
  left: 25%;
}
.grade-5,
.grade-6 {
  height: 50px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
}
