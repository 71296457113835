.progress-cards-container {
  width: 95%;
  /* max-height: 78vh; */
  margin: 2% auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 50px;
  /* overflow-y: auto; */
}

.progress-card-item {
  width: 20%;
  padding: 5px;
  border-radius: 10px;
  background: #d4e5ff;
  height: 300px;
  box-shadow: 2px 2px 10px #888888;
  z-index: 10;
  transition: all 0.2s;
}
.progress-card-item:hover {
  background: #afc8ec;
  transform: scale(0.97);
}

.progress-card-item-img-section {
  padding: 5px;
  position: relative;
  height: 50%;
}
.progress-card-item-img-section img {
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.progress-card-item-img-section .img-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15%;
  left: 25%;
  z-index: 10 !important;
  font-size: 1.7rem;
  color: #fff;
  font-weight: 700;
}

.progress-card-item-content {
  display: flex;
  flex-direction: column;
}
.progress-card-item-content .progress-card-subject {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  padding-left: 10px;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}

.progress-card-item-progress {
  margin-top: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.progress-card-item-progress .progress-card-item-progress-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.progress-card-item-progress-text .progress-complete-level {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}
/* .progress-card-item-progress-chart {
  border: 2px solid #fff;
  border-radius: 50%;
} */
.progress-card-item-progress-chart span {
  width: 50px !important;
  height: 50px !important;
  font-weight: 500;
}
