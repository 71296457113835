.frame-152 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3px;
  height: 43px;
  width: 640px;
}

.group-30 {
  align-self: stretch;
  height: 20px;
  margin-right: -2px;
  min-width: 642px;
  position: relative;
}

.unit-1-1 {
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-shadow: 0px 3px 2px #0000004c;
  top: 0;
  white-space: nowrap;
  width: 109px;
}

.arrow-down-2-1 {
  height: 20px;
  left: 602px;
  position: absolute;
  top: 0;
  width: 38px;
}

.address-27 {
  letter-spacing: 0;
  line-height: 20px;

  white-space: nowrap;
  width: fit-content;
}

.frame-152.frame-154,
.frame-152.unit-2-1 {
  margin-top: 13px;
}

.frame-152.frame-154 .unit-1-1,
.frame-152.unit-2-1 .unit-1-1,
.frame-152.frame-153 .unit-1-1,
.frame-152.unit-3 .unit-1-1 {
  width: 119px;
}

.frame-152.frame-153,
.frame-152.unit-3 {
  justify-content: center;
  margin-top: 10px;
}
