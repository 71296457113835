.mobile-nav-drawer {
  display: flex;
  justify-content: space-around;
  background-color: white;
  /* padding: 12px; */
  position: fixed;
  bottom: -1px;
  width: 100%;
  z-index: 20;
  height: 55px;
}

.mobile-nav-drawer-nav-item {
  /* display: flex; */
  align-items: center;
  text-decoration: none;
  color: white;
  padding: 12px 16px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0);
}

.mobile-nav-drawer-nav-item span {
  width: 0;
  overflow: hidden;
}

.mobile-nav-drawer-nav-item:focus {
  text-decoration: none;
  color: white;
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0.2s;
}

.mobile-nav-drawer-nav-item:focus span {
  width: 100%;
  margin-left: 8px;
  transition: 0.2s;
}

*:focus {
  outline: none;
  border: none;
}
