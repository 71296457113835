.frame-30 {
  align-items: center;
  display: flex;
  gap: 15px;
  position: relative;
  width: fit-content;
}

.math-7,
.physics-5,
.chemistry-7,
.biology-5,
.language-16 {
  height: 30px;
  letter-spacing: 0;
  line-height: 27px;
  margin-top: -1.50px;
  white-space: nowrap;
  width: 105.57px;
}

