@media only screen and (max-width: 650px) {
  .btns {
    width: 80px !important;
    height: 80px !important;
  }
}
.videos-dropdown-for-my-learning,
.videos-dropdown-for-unpaid-class {
  background-color: var(--alto-2);
  border-radius: 10px;
  height: fit-content;
  width: 80%;
  overflow: hidden;
  margin-top: 2%;
}

.flex-col-1,
.flex-col-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 10px;
  min-height: 184px;
  position: relative;
  top: 10px;
  width: 640px;
}

.line-9-stroke,
.line-9-stroke-1 {
  height: 1px;
  margin-top: 10px;
  object-fit: cover;
  width: 640px;
}

.main-accord {
  background-color: red !important;
  z-index: 100;
}

.accord-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 15px; */
  gap: 5px;
}
.accord-title {
  font-weight: 600;
  font-size: 1rem;
  color: #1d557c;
}
.accord-sub-title {
  display: flex;
  /* margin-top: 10px; */
  font-weight: 600;
  color: #cccc;
  /* white-space: nowrap; */
  gap: 5px;
  align-items: center;
}
.accord-sub-title2 {
  font-weight: 600;
  color: #999999;
  line-height: 0;
  margin-top: 8px;
  margin-bottom: 20px;
}

.btns {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  outline: none;
  background: #f64747;
  border: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btns::before {
  content: "";
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: #1f3a93;
  transform: scale(0);
  transform-origin: 83% 86%;
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}

.btns.active::before {
  transition-timing-function: ease;
  transform: scale(1);
}

.btns .play {
  width: 40px;
  height: 40px;
  background: #fff;
  margin-left: 15px;
  z-index: 100;
  clip-path: polygon(0 0, 80% 48%, 0 100%, 0% 100%);
  transition: clip-path 0.5s ease;
  transition-delay: 0.4s;
}

.btns .play.active {
  transition: clip-path 0.1s;
  clip-path: polygon(0 0, 27% 0, 27% 100%, 0% 100%);
}

.small-box {
  width: 18px;
  height: 15px;
  background: #fff;
  position: absolute;
  left: 65px;
  z-index: 100;
  bottom: 59px;
  opacity: 0;
  transition: opacity 1s;
}

.small-box.pauseToPlay {
  animation: pauseToPlay 0.4s ease alternate-reverse;
}

@keyframes pauseToPlay {
  10% {
    transform: rotate(90deg);
    left: 73px;
  }
  20%,
  40% {
    transform: rotate(180deg);
    left: 92px;
  }
  50% {
    left: 92px;
    height: 66px;
    bottom: 57px;
    transform: rotate(180deg);
  }
  100% {
    left: 92px;
    height: 66px;
    bottom: 57px;
    transform: rotate(180deg);
  }
}

.small-box.active {
  opacity: 1;
  transition: opacity 1s;
  animation: move 0.8s cubic-bezier(0.52, 1.64, 0.37, 0.66) forwards;
  animation-delay: 0.2s;
}

@keyframes move {
  10% {
    transform: rotate(90deg);
    left: 73px;
  }
  20%,
  40% {
    transform: rotate(180deg);
    left: 92px;
  }
  50% {
    left: 92px;
    height: 66px;
    bottom: 57px;
    transform: rotate(180deg);
  }
  100% {
    left: 92px;
    height: 66px;
    bottom: 57px;
    transform: rotate(180deg);
  }
}
