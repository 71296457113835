.supported-grades-swiper-component {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 45px;

  min-height: 435px;
  position: absolute;
  top: 0%;
  width: 100%;
  padding-top: 5%;
}
.supported-grades-swiper-component .slick-track {
  display: flex;
  justify-content: center;
  gap: 100px;
}

.supported-grades-swiper-component .supp-grades-slider-comp .slick-slide {
  width: fit-content !important;
}
.supp-grades-slider-comp .slick-prev {
  margin-left: 1%;
}
.slick-next {
  margin-right: -4%;
}

@media only screen and (max-width: 600px) {
  .students-feedback-section {
    width: 100% !important;
  }
  .supported-grades-swiper-component {
    padding-top: 10%;
  }
  .supported-grades-swiper {
    width: 100%;
  }
  .supported-grades-card {
    margin-left: 0%;
  }
  .visit-all-grades-btn-section {
    width: fit-content !important;
  }
  .supported-grades-swiper .supported-grades-card-1 {
    margin-right: 0 !important;
    height: 320px !important;
  }
  .supported-grades-swiper .swiper {
    padding: 0px 0px !important;
  }

  .sup-material-item {
    font-size: 0.7rem;
  }
  .sup-material-slider {
    height: 70px !important;
  }
}

.supported-grades-swiper {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  gap: 75px;
  overflow: hidden;
  position: relative;
  width: 90%;
  margin: auto;
}
.supported-grades-swiper .swiper {
  padding: 0 50px;
}

/**swiper style starts here */
.supported-grades-swiper .swiper-slide {
  display: flex;
  justify-content: center;
}
/* Move the navigation arrows to the sides */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  width: 50px;
  height: 50px;

  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */
}

.swiper-button-prev {
  left: -10px;
}

.swiper-button-next {
  right: -10px;
}

.supported-grades-swiper .slick-slider {
  overflow: hidden;
  width: 85%;
}

.supported-grades-card {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 340px;
  overflow: hidden;
  position: relative;
  width: 310px;
}

.grade {
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 20px;
  margin-top: 19px;
  white-space: nowrap;
}

.language-for-grade_4 {
  align-items: flex-start;
  display: flex;
  gap: 626px;
  margin-left: 20px;
  margin-top: 24px;
  min-width: 104px;
}

.group_4 {
  height: 20px;
  margin-left: -615px;
  margin-top: -1252px;
  width: 18px;
}

.language-5 {
  letter-spacing: 0;
  line-height: 20px;
  min-height: 18px;
  text-shadow: 0px 3px 2px #0000004c;
  white-space: nowrap;
}

.language-for-grade_5 {
  align-items: flex-start;
  display: flex;
  gap: 1011px;
  margin-left: 20px;
  margin-top: 24px;
  min-width: 104px;
}

.group_5 {
  height: 20px;
  margin-left: -1000px;
  margin-top: -1252px;
  width: 18px;
}
