.notification-items-container {
  display: block;
}

.load-more-notification {
  color: #304ffe;
  font-family: "Roboto";
  cursor: pointer;
}

.notification-container {
  position: relative;
}
@media only screen and (max-width: 650px) {
  .notification-container {
    position: absolute;
    right: 4%;
  }
}
