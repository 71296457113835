.login-btn,
.login-btn-1,
.login-btn-2,
.login-btn-3,
.login-btn-4 {
  align-items: center;
  border: 1px solid;
  border-color: var(--blue);
  border-radius: 30px;
  display: flex;
  gap: 10px;
  height: 22px;
  justify-content: center;
  /* margin-left: 30px; */
  padding: 20px;
  width: 100px;
  cursor: pointer;
}
.login {
  background-color: transparent;
  cursor: pointer;
  border: none;
}
.login-btn:hover {
  background-color: var(--blue);
  transition: 0.3s;
}
.login-btn:hover .login {
  color: #fff;
  transition: 0.3s;
}

.login,
.login-1,
.login-2,
.login-3,
.login-4 {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -6px;
  margin-top: -8px;
  width: fit-content;
}

.login-btn-bg {
  background-color: transparent !important;
  color: #304ffe !important;
  border: none !important;
  font-weight: 500 !important;
}
.login-btn-bg:hover {
  background-color: transparent !important;
}

.login-btn:hover .login-btn-bg {
  color: #fff !important;
}
