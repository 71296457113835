.ver-card {
  width: 80%;
  padding: 10px;
  border-radius: 20px;
  background: #fff;
  border: none;
  position: relative;
}

.code-container .form-control {
  margin-right: 12px;
  padding: 0.5rem !important;
  text-align: center;
}

.code-container .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ff8880;
  outline: 0;
  box-shadow: none;
}
.code-container input {
  width: 2em !important;
  text-align: center;
  height: 2rem !important;
  border: 1px solid grey;
  border-radius: 8px;
  margin-top: 5%;
}
.ver-container .px-3 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.cursor {
  cursor: pointer;
}
.resend-disabled {
  color: #ccc;
}

@media only screen and (max-width: 650px) {
  .code-container .form-control {
    padding: 0.5rem 0 !important;
  }
}
