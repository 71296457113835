.materials-slider::-webkit-scrollbar {
  display: none;
  width: 0;
}

.materials-slider {
  align-items: flex-end;
  align-self: center;
  display: flex;
  gap: 12px;
  height: 25px;
  margin-top: 23px;
  overflow: hidden;
  overflow-x: scroll;
  padding: 0.4px 0;
  position: relative;
  width: 270px;
  overflow-x: scroll;
}

.frame {
  align-items: center;
  background-color: var(--blue-2);
  border-radius: 6px;
  display: flex;
  gap: 12px;
  justify-content: center;
  padding: 3.6px 12px;
  width: fit-content;
}

.math,
.physics,
.chemistry,
.math_2,
.physics_2,
.chemistry_2,
.math_3,
.physics_3,
.chemistry_3,
.math_4,
.physics_4,
.chemistry_4,
.math_5,
.physics_5,
.chemistry_5 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.2px;
  width: fit-content;
}
