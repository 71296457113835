

body {
  overflow-x: hidden;
}
.teachers-main-container {
  width: 100%;
}
.title-line-container {
  height: 100px;
  position: relative;
  margin: 3% auto;
  width: 90%;
}

.teachers-cards-container {
  display: flex;
  width: 90%;
  height: 50vh;
  flex-wrap: wrap;
  margin: 2% auto;
  gap: 30px;
  padding: 10px;
}
/* .teacher-card-item {
  width: 25%;
}

@media only screen and (max-width: 650px) {
  .teacher-card-item {
    width: 50%;
  }
} */
