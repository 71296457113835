/*Scroll bar customization*/
/* STYLING SCROLLBAR */

::-webkit-scrollbar {
  width: 1vw; /* THIS WILL RESET THE DEFAULT SCORLLBAR STYLING */
}

/* TO STYLE THE SCROLLBAR TRACK */
::-webkit-scrollbar-track {
  background-color: #ffffff; /* THIS WILL SET THE COLOR OF THE SCROLLBAR TRACK */
}

/* TO STYLE THE SCROLLBAR THUMB */
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 210, 210);
  border: 0.3vw solid #ffffff;
  border-radius: 5vw;
}

.all-grades {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  height: 1370px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.overlap-group-4 {
  height: 1290px;
  margin-left: -1px;
  margin-top: 80px;
  position: relative;
  width: 1803px;
  z-index: 1;
}

.all-grades-1 {
  height: 30px;
  left: 0;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: 64px;
  width: 155px;
}

.all-grade-title-section .all-grades-1 {
  position: static !important;
}

/*Cards Container starts here*/
.all-grade-page .grade-cards-container {
  display: flex;
  align-items: flex-start;
  width: 90%;
  justify-content: center;
  /* height: 100vh; */
  flex-wrap: wrap;
  margin: 1% auto 10% auto;
  gap: 15px;
}

.custom-dropItem {
  width: 150px;
}

@media only screen and (max-width: 650px) {
  .my-learning-title-section .drop-down-container {
    width: 100% !important;
  }
  .dropdown-item1,
  .dropdown-item2,
  .dropdown-item3 {
    width: 33% !important;
  }
  .all-grade-page .grade-scroll.scroll {
    width: 100% !important;
  }
  .all-grade-page .grade-cards-container {
    margin-left: 5px !important;
  }
  .all-grades-1 {
    left: 2%;
  }
  .my-learning-title-section .drop-down-container {
    width: fit-content !important;
  }
  .drop-down-container {
    gap: 0px !important;
  }
  .css-ece9u5 {
    min-width: 90px !important;
  }
  .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    display: none !important;
  }
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    max-width: 50px !important;
  }
  .all-grade-page .grade-cards-container {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 5%;
    gap: 15px;
    /* height: 170vh; */
    /* overflow-y: overlay; */
  }
  .my-learnin-course-6-card {
    width: 160px !important;
  }
  .all-grade-page .valign-text-middle {
    align-items: center !important;
    font-size: 1.3rem;
  }
  .all-grade-page .title-redeam-section {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .all-grade-page .drop-down-container {
    align-self: flex-end;
    margin-bottom: 20px;
  }
}

.my-learnin-course-1-card-1 {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0px 0px 8px #00000040;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 250px;
  left: 181px;
  overflow: hidden;
  padding: 5.4px 5.7px;
  position: absolute;
  top: 140px;
  width: 200px;
}

.grade-9-english-4 {
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 9.29px;
  margin-top: 9px;

  white-space: nowrap;
}

.nav-bar-3 {
  align-items: center;
  background-color: var(--white);
  box-shadow: 0px 2px 6px #0000001a;
  display: flex;
  height: 80px;
  left: 0;
  padding: 0 70px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

.logo-5 {
  cursor: pointer;
  height: 40px;
  width: 183px;
}

.grade-scroll {
  /* default styles */
  /* overflow: hidden;
  height: 90vh;
  overflow-y: hidden; */
  padding: 10px !important;
  /* border-bottom: 1px solid #ccc; */
}

.grade-scroll.scroll {
  /* overflow: scroll;
  overflow-x: hidden; */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on touch devices */
}
/*
.grade-scroll.scroll::-webkit-scrollbar {
  width: 0;  Remove scrollbar space 
  background-color: transparent; Optional: just make scrollbar invisible 
}*/

.all-grade-title-section {
  position: relative;
  width: 85%;
  margin: 5% auto 0;
}
.my-learning-title-section .title-lines {
  width: 100% !important;
  position: static !important;
}

.my-learning-title-section .drop-down-container {
  display: flex !important;
  flex-direction: row !important;
  gap: 20px;
  /* margin-bottom: 5px; */
  /* width: 20%; */
}
.my-learning-title-section .dropdown-item1 {
  position: static !important;
  /* background-color: #fff; */
  /* width: 50%; */
}
.all-grade-title-section .dropdown-item2 {
  position: static !important;
  background-color: #fff;
  /* width: 50%; */
}
.all-grade-title-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
/* .datehide {
  display: none;
} */
