.buy-grades-dropdown {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  width: fit-content;
}

.group-57 {
  height: 30px;
  min-width: 540px;
}

.overlap-group-16 {
  height: 30px;
  position: relative;
  width: 540px;
}

.group-51-1 {
  align-items: flex-start;
  display: flex;
  gap: 248px;
  height: 30px;
  left: 0;
  min-width: 540px;
  position: absolute;
  top: 0;
}

.arrow-down-2-2 {
  height: 21px;
  width: 30px;
}

.frame-305 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 7.5px;
  height: 1px;
  left: 38px;
  overflow: hidden;
  position: absolute;
  top: 29px;
  width: fit-content;
}

.line-1-7 {
  height: 1px;
  min-width: 540px;
}

.line-19 {
  height: 1px;
  margin-bottom: -1.00px;
  min-width: 540px;
}

