.intro-body {
  overflow: hidden;
}

.introduction-video-my-learning-more-section {
  align-items: center;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  /* height: 1620px; */
  position: relative;
  width: 100%;
}
.introduction-video-my-learning-more-container {
  width: 80%;
}
.nav-bar-5 {
  align-items: center;
  justify-content: space-between;
  background-color: var(--white);
  box-shadow: 0px 2px 6px #0000001a;
  display: flex;
  gap: 1055px;
  height: 50px;
  left: 0;
  padding: 0 50px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
}

.logo-9 {
  cursor: pointer;
  height: 40px;
  margin-left: 2px;

  object-fit: contain;
}

.flex-row-15 {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin: 6% 0 0 auto;
  width: 95%;
  position: relative;
  z-index: 2;
}

.flex-col-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  /* gap: 30px; */
  gap: 10px;
  min-height: 570px;
  width: 70%;
}
@media only screen and (min-width: 1405px) {
  .video-section {
    height: 550px !important;
  }
}
@media only screen and (min-width: 1205px) {
  .video-section {
    height: 650px;
  }
}
.video-section {
  position: relative;
  /* height: 510px; */
  height: 300px;
  width: 100%;
  /* margin-bottom: 2%;
  margin-top: 3%; */
}
.get-code-container {
  width: 100%;
  position: relative;
}
.get-code {
  margin-left: auto;
  background-color: green;
  padding: 10px;
  border-radius: 10px;
  color: white;
  position: absolute;
  position: relative;
  font-size: 1rem;
  cursor: pointer;
  margin-top: -23px;
}
.get-code:hover {
  background-color: rgb(3, 88, 3);
}

.video-section video {
  width: 100%;
  height: 100%;
  z-index: -1;
}

.video-section img {
  width: 100%;
  z-index: -1;
}

.materials-1 {
  align-items: flex-start;
  display: flex;
  gap: 25px;
  /* margin-left: 40px; */
  width: 95%;
  flex-wrap: wrap;
}

.chemistry-3 {
  align-items: center;
  background-color: var(--blue);
  border-radius: 7px;
  display: flex;
  gap: 14px;
  height: 30px;
  justify-content: center;
  padding: 4.2px 14px;
  width: fit-content;
}

.chemistry-4,
.math-4,
.physics-3,
.biology-3,
.language-14 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -2.1px;
  width: fit-content;
  cursor: pointer;
  padding: 10px;
  white-space: nowrap;
}

.math-3 {
  align-items: center;
  background-color: var(--blue-2);
  border-radius: 7px;
  display: flex;
  gap: 14px;
  height: 30px;
  justify-content: center;
  padding: 20px;
  width: fit-content;
}

.material-selected button {
  border: 3px solid #569ec1 !important;
  border-radius: 10px;
  background-color: blue !important;
}

.materials-active-btn button {
  background-color: red;
  color: #fff;
}
.materials-non-active-btn button {
  background-color: #696767;
  color: #ffffff;
}
.materials-non-active-btn button:hover {
  background-color: #999999 !important;
  color: #ffffff !important;
}
/* .materials-btn:first-child {
  border: 1px solid #043bff;
  border-radius: 10px;
} */
.materials-item {
  align-items: center;
  background-color: var(--mercury-2);
  border-radius: 7px;
  display: flex;
  gap: 14px;
  height: 40px;
  justify-content: center;
  padding: 4.2px 14px;
  width: fit-content;
}
.materials-btn {
  white-space: nowrap;
}
.materials-btn button {
  background-color: #9999;
  border: none;
}
.materials-active-btn button {
  background-color: #569ec1;
}

.flex-col-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0% auto 5% auto;

  width: 90%;
  z-index: 1;
}

.more-and-faq-titles {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-left: 70px;
  min-height: 30px;
  width: 994px;
}

.flex-row-16 {
  align-items: flex-start;
  display: flex;
  gap: 70px;
  height: 20px;
  min-width: 172px;
}

.place-6 {
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
  width: 57px;
}

.faq {
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
}

.lines {
  height: 3px;
  width: 990px;
}

.pdf-files-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-top: 30px;
}

.description-about-th {
  letter-spacing: 0;
  line-height: 25px;
  margin-left: 0.19px;
  min-height: 80px;
  width: 990px;
}

.pdf-titles {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 120px;
  position: relative;
  width: fit-content;
}

.line-9-stroke-2 {
  height: 1px;
  min-width: 990px;
}

.pdf-3 {
  height: 20.95px;
  margin-right: -2px;
  min-width: 992px;
  position: relative;
}

.pdf-file-3-related-to-video-1 {
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.download-btn {
  height: 20px;
  left: 970px;
  position: absolute;
  top: 1px;
  width: 20px;
}

.footer-and-unlock-the-power-section-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  /* margin-top: 200px; */
  position: relative;
  width: 100%;
}

.footer-5 {
  background-color: var(--haiti);
  height: 400px;
  min-width: 1440px;
}

.flex-col-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 70px;
  min-height: 289px;
  position: relative;
  top: 70px;
  width: 1304px;
}

.flex-row-17 {
  align-items: flex-start;
  display: flex;
  gap: 163px;
  height: 170px;
  min-width: 1192px;
}

.about-schooltube-in-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 170px;
  width: 422px;
}

.logo-10 {
  height: 40px;
  width: 183px;
}

.lorem-ipsum-dolor-si-7 {
  letter-spacing: 0;
  line-height: 20px;
  width: 420px;
}

.contact-us-section-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  width: 326px;
}

.contact-us-5 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 2.65px;
  min-height: 30px;
}

.phone-number-6 {
  align-items: center;
  display: flex;
  gap: 14px;
  margin-top: 20px;
  min-width: 237px;
}

.phone-1-5 {
  height: 19px;
  width: 18px;
}

.address-33 {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 203px;
  white-space: nowrap;
}

.email-5 {
  align-items: center;
  display: flex;
  gap: 15px;
  margin-left: 0.5px;
  margin-top: 10px;
  min-width: 326px;
}

.letter-1-5 {
  height: 16px;
  width: 17px;
}

.schooltubeonlinehotmailcom-5 {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 292px;
  white-space: nowrap;
}

.follow-us-section-5 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 70px;
  width: 117px;
}

.follow-us-5 {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 2px;
  min-height: 30px;
  min-width: 115px;
}

.social-media-icons-5 {
  height: 30px;
  margin-right: 2px;
  width: 80px;
}

.scroll-top-btn-5 {
  align-self: flex-end;
  cursor: pointer;
  height: 58px;
  margin-top: 6px;
  width: 58px;
}

.line-1-5 {
  height: 1px;
  margin-top: 15px;
  object-fit: cover;
  width: 1300px;
}

.flex-row-18 {
  align-items: flex-start;
  display: flex;
  gap: 782px;
  margin-top: 19px;
  min-width: 1300px;
}

.copyright-2022-schoo-5 {
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  width: 356px;
}

.powered-by-brainkets-5 {
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  width: 162px;
}

/*faq section here*/
.intro-faq-section {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  overflow: overlay;
  transition: all 1s;
}
.intro-faq-section .intro-faq-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* align-items: center;
  justify-content: center; */
  padding: 15px;
  background-color: #ccc;
  border-radius: 10px;
}
.intro-faq-section .intro-faq-item .faq-item-img {
  width: 5%;
  height: 35%;
  margin-top: 15px;
}
.intro-faq-section .intro-faq-item .faq-item-img img {
  width: 100%;
  height: 100%;
}
.intro-faq-section .intro-faq-item .faq-item-qa-container {
  width: 90%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 1.4rem;
  font-family: "Popins";
  font-weight: 700;
}
.intro-faq-item .faq-item-qa-container strong {
  color: #304ffe;
  font-size: 1.4rem;
}

.faq-btn-question {
  width: 80%;
  color: #fff;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  padding: 20px;
  border-radius: 10px;
  background-color: #304ffe;
  margin-left: 5%;
  margin-top: 7%;
  cursor: pointer;
  transition: all 0.2s;
}
.faq-btn-question button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
}

.faq-btn-question:hover {
  background-color: #1227a0;
}
.faqs-loading {
  width: 100%;
  display: flex;
  justify-content: center;
}
.faqs-loading img {
  width: 200px;
  height: 100px;
}
.load-more {
  color: #304ffe;
}

/*for video */
.video-control select option {
  border: none;
  outline: none;
}
.video-control select option:hover {
  background-color: #333;
}

video {
  transition: ease-in-out 0.6s;
}
.resolution-select {
  position: absolute;
  right: 14%;
  bottom: 6%;
  border: none;
  outline: none;
  font-size: 1.2rem;
  padding: 5px;
  background-color: transparent;
  color: #ccc;
  -webkit-appearance: none; /* Remove default arrow for Safari */
  -moz-appearance: none; /* Remove default arrow for Firefox */
  appearance: none; /* Remove default arrow for other browsers */
  cursor: pointer;
  transition: ease-in-out 0.5s;
}
.resolution-select option {
  color: #000;
  font-weight: 500;
}
.video-section:hover .resolution-select {
  display: block;
  transition: ease-in-out 1s;
}
.video-section:focus-within .resolution-select {
  display: block;
  transition: ease-in-out 1s;
}
.video-image-thumbnail {
  object-fit: contain;
}
/* .resolution-select:hover {
  background-color: #ccc;
} */

.sticky-accordion-container {
  position: relative;
  right: 0;
  width: 30%;
  margin-left: 10px;
}

/*Shimmer Loading Effect */
.shimmer-container {
  display: flex;
  flex-direction: row;
}
.shimmer-container .shimmer-video-section {
  width: 65%;
  margin: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.shimmer-container .shimmer-video {
  width: 100%;
  height: 510px;
  background-color: #ccc;
}
.shimmer-video-section .shimmer-video-title {
  width: 30%;
  height: 70px;
  background-color: #ccc;
}
.shimmer-video-section .shimmer-materials-container {
  width: 50%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}
.shimmer-materials-container .shimmer-material-item {
  height: 40%;
  width: 15%;
  background-color: #ccc;
  border-radius: 10px;
}

.shimmer-container .shimmer-accordion {
  width: 30%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 1px;
  background-color: #fff;
  margin: 15px;
}
.shimmer-accordion .shimmer-accordion-item {
  background-color: #ccc;
  width: 100%;
  height: 20%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.shimmer-video,
.shimmer-video-title,
.shimmer-material-item,
.shimmer-accordion-item {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(
    to right,
    #ccc 0%,
    #fff 20%,
    #ccc 50%,
    #fff 70%,
    #ccc 100%
  );
  background-size: 2000px 100%;
}

/*request code style here*/
.request-codes-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: scroll;
  overflow: overlay;
  height: 80vh;
}
.req-code-container {
  background-color: #ccc;
  padding: 20px 50px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.req-code-text-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.req-code-title {
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "Roboto";
  color: #304ffe;
}
.req-code-sub-text {
  display: flex;
  flex-direction: row;
  gap: 20px;
  color: #304ffe;
  font-weight: 400;
}
.req-code-btn button {
  color: cyan;
  padding: 10px;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
}
.req-code-btn button:hover {
  background-color: rgb(2, 80, 2);
}

.grade-title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* white-space: nowrap; */
}
@media only screen and (max-width: 1024px) {
  .introduction-video-my-learning-more-section {
    z-index: 70;
  }
  .flex-row-15 {
    margin: 0;
  }
}
/**responsivivty*/
@media only screen and (max-width: 650px) {
  .nav-bar-5 {
    gap: 0px;
    padding-left: 40px !important;
    padding-right: 5px !important;
  }
  .nav-bar-5 .css-i4bv87-MuiSvgIcon-root {
    left: 10px !important;
  }
  .logo-9 {
    width: fit-content !important;
  }
  .flex-row-15 {
    width: 100% !important;
    /* height: 127vh; */
    overflow-y: hidden;
    margin: 0px !important;
  }
  .flex-col-4 {
    width: 97% !important;
    padding:5px;
  }
  .materials-1 {
    margin-left: 10px;
  }
  .flex-col-5 {
    width: 100%;
  }
  .css-1x97c6v {
    width: 100% !important;
  }
  .introduction-video-my-learning-more-section {
    height: 100% !important;
  }
  .video-section img {
    height: 90%;
  }
  /* .css-i4bv87-MuiSvgIcon-root {
    left: 35% !important;
    top: 35% !important;
  } */
  .grade-title-container {
    white-space: normal;
    /* margin-top: 82% !important; */
  }
  .grade-title-container .intro-grade-title {
    padding-left: 5px;
  }
  .material-and-video-name {
    padding-left: 5px;
  }
  .get-code-container {
    width: 100% !important;
    /* position: absolute; */
  }
  /* .get-code { */
  /* left: 55%; */
  /* margin-left: auto; */
  /* } */
  .resolution-select {
    right: 40% !important;
    bottom: 10%;
  }
  .video-section {
    /* margin-top: 10% !important;
    position: fixed; */
    top: 0 !important;
    z-index: 10;
    height: 300px;
    /* height: 100%; */
  }
  .shimmer-container {
    flex-direction: column;
  }
  .shimmer-container .shimmer-video-section {
    width: 100% !important;
  }
  .shimmer-container .shimmer-accordion {
    width: 90%;
  }
  .css-1wnsr1i {
    width: 100% !important;
    padding: 15px !important;
    top: 53% !important;
  }
  .req-code-container {
    padding: 20px 10px;
  }
  .materials-1 .swiper-slide {
    width: fit-content !important;
  }
  .square {
    display: none;
  }
}
.materials-1 .swiper-button-prev,
.materials-1 .swiper-button-next {
  font-size: 1.5rem !important;
  top: 100% !important;
}
.intro-grade-title {
  background: linear-gradient(to right, #ff0000, #0000ff);
  -webkit-text-fill-color: transparent;
  font-size: 20px;
  -webkit-background-clip: text;
}

/**for the progress*/
/* .square {
  position: absolute;
  top: 20px;
  right: -50px;
  width: 100px;
  height: 100px;
  background-color: red;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  z-index: 100;
  cursor: pointer;
}
.square:hover {
  right: 0px;
} */
.square {
  position: absolute;
  /* top: 60px; */
  top: 0px;
  right: -90px;
  width: 120px;
  height: 80px;
  background-color: #fdf9f9;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  z-index: 100;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.square::before,
.square::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-style: solid;
  border-color: transparent red transparent transparent;
}

.square::before {
  border-width: 30px 0 0 30px;
  width: 0;
  height: 0;
}

.square::after {
  border-width: 0 0 30px 30px;
  width: 0;
  height: 0;
  top: auto;
  bottom: 0;
}

.square:hover {
  right: -5px;
}

.square-title {
  color: #fff;
  background-color: #304ffe;
  padding: 10px 5px;
  writing-mode: vertical-rl;
}

.intro-progress-container {
  width: 70%;
  display: flex;
  justify-content: center;
}

/* new */
.accord-title-container {
  align-items: center;
}

.accord-title {
  max-width: 80%;
}

@media only screen and (max-width: 650px) {
  .accord-title {
    max-width: 65%;
  }
}
.video-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.video-element {
  width: 100%;
  height: 100%;
  display: block;
}

.controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none; /* Initially hide the controls */
  gap: 10px;
}

.video-container:hover .controls {
  display: flex; /* Show controls on hover */
}

.control-btn {
  border: none;
  color: white;
  font-size: 1.5rem;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

/* .control-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
} */

.control-btn svg {
  width: 24px; /* Adjust size as needed */
  height: 24px;
}

.rewind-btn {
  position: relative;
  left: -100%; /* Adjust position as needed */
}

.fast-forward-btn {
  position: relative;
  left: 100%; /* Adjust position as needed */
}