.buy-course-popup {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  height: 1024px;
  padding: 0 400px;
  width: 1440px;
}

.select-grade-popup-with-scroll {
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  display: flex;
  height: 800px;
  margin-top: 112px;
  overflow: hidden;
  padding: 18px 0;
  width: 640px;
}

.select-your-grade::-webkit-scrollbar,
.frame-376::-webkit-scrollbar {
  display: none;
  width: 0;
}

.select-your-grade {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 20px;
  display: flex;
  height: 742px;
  overflow: hidden;
  overflow-y: scroll;
  padding: 0 50px;
  width: 640px;
}

.frame-376 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
  overflow-x: scroll;
  width: fit-content;
}

.select-your-grade-1 {
  height: 30px;
  letter-spacing: 0;
  line-height: 27px;
  margin-top: -1.00px;
  white-space: nowrap;
  width: 217.5px;
}

.frame-307 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 60px;
  position: relative;
  width: fit-content;
}

.frame-306 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 75px;
  width: fit-content;
}

.notes-1 {
  border: 1.5px solid;
  border-color: var(--mountain-mist);
  border-radius: 15px;
  height: 180px;
  min-width: 540px;
  overflow: hidden;
  position: relative;
}

.add-notes {
  left: 30px;
  letter-spacing: 0;
  line-height: 27px;
  position: absolute;
  top: 14px;
  white-space: nowrap;
}

.group-56-1 {
  height: 60px;
  min-width: 540px;
  position: relative;
}

.frame-14 {
  align-items: flex-start;
  background-color: var(--blue);
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  height: 60px;
  left: 285px;
  overflow: hidden;
  padding: 10.5px 28.4px;
  position: absolute;
  top: 0;
  width: 255px;
}

.submit-request {
  letter-spacing: 0;
  line-height: normal;
  min-height: 36px;
  min-width: 196px;
  text-align: center;
}

