.btn-component,
.btn-component-1 {
  align-items: center;
  align-self: center;
  background-color: var(--slimy-green);
  border-radius: 5px;
  display: flex;
  gap: 10px;
  height: 30px;
  justify-content: center;
  margin-top: 25px;
  padding: 4px 10px;
  width: 260px;
}
button {
  border: none;
  background-color: transparent;
  width: 100%;
}

.get-code,
.get-code-1 {
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
