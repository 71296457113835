.main-div {
  /* default styles */
  overflow: hidden;
  height: 70vh;
  overflow-y: hidden;
}

.main-div.scroll {
  overflow: scroll; /* Show scrollbar when needed */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on touch devices */
}
.main-div.scroll::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background-color: transparent; /* Optional: just make scrollbar invisible */
}

.my-question-scrollable-container {
  background: #f4f4f4;
  padding: 5px 0;
}
.profile-my-question {
  background: #d4e5ff;
  box-shadow: 0px 4px 8px rgba(184, 184, 210, 0.25);
  border-radius: 10px;
  width: 80%;
  margin: 2% auto;
  display: flex;
  flex-direction: row;

  padding: 10px;
}
.profile-my-question .my-question-user-img {
  width: 50px;
  height: 50px;
  padding: 5px;
  margin-top: 5px;
  border-radius: 50%;
}

.my-question-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  justify-content: center;
}
.my-question-container .question-answer-container {
  display: flex;
  flex-direction: column;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  gap: 10px;
}
.question-answer-container strong {
  color: blue;
}
.question-answer-container .answer-section {
  margin-top: 10px;
  color: #7a7979;
}
.question-date {
  margin-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #999999;
}
