/* screen - login-popup */

.login-form {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 20px;
  display: flex;
  gap: 25px;
  height: 640px;
  margin-left: 20px;
  overflow: hidden;
  padding: 0 15px;
  width: 750px;
}

.image {
  align-self: center;
  background-size: contain;
  background-repeat: no-repeat;
  /* transform: translateY(10%); */
  border-radius: 15px;
  height: 100%;
  width: 100%;
}

.register-image {
  align-self: center;
  /* background-image: url("../../static/img/login-imgs/reg-image.png"); */
  border-radius: 15px;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%, 50%;
}
.ver-image {
  align-self: center;
  background-image: url("../../static/img/login-imgs/verify-image.png");
  background-size: cover;
  border-radius: 15px;
  height: 510px;
  width: 100%;
}

.flex-col-login {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  /* margin-top: 20px; */
  min-height: 469px;
  width: 100%;
}

.icon-close {
  align-self: flex-end;
  height: 30px;
  width: 30px;
}

.welcome-back {
  height: 40px;
  letter-spacing: 0;
  line-height: normal;
  margin-right: 5px;
  /* margin-top: 29px; */
  text-align: center;
  width: 182px;
}

.welcome-back-please-enter-you-details {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 5px;
  min-height: 20px;
  min-width: 259px;
  text-align: center;
}

.phone-number-or-username {
  align-self: flex-start;
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 40px;
  width: 200px;
}

.x-field {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--mist-gray);
  border-radius: 10px;
  display: flex;
  height: 40px;
  margin-right: 5px;
  margin-top: 6px;
  overflow: hidden;
  padding: 10px 15px;
  width: 325px;
}

.enter-p {
  height: 18px;
  letter-spacing: 0;
  line-height: normal;
}

.password {
  align-self: flex-start;
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 19px;
}

.forget-password {
  float: right;
  letter-spacing: 0;
  line-height: normal;
  margin: 5px 5px 15px 0;
  min-height: 20px;
  min-width: 116px;
  font-size: 0.8rem;
}
.forget-password button {
  background: transparent;
  color: #0d6efd;
  border: none;
  font-size: 0.8rem;
}

.btn-component {
  align-items: center;
  background-color: var(--blue);
  border-radius: 10px;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  margin-right: 5px;
  margin-top: 21px;
  padding: 4px 10px;
  width: 325px;
}

.read-more {
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  width: fit-content;
}

.dontt-have-an-account-register-now {
  color: transparent;
  cursor: pointer;
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxs);
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-right: 5px;
  margin-top: 39px;
  min-height: 20px;
  min-width: 248px;
  text-align: center;
}

.span0 {
  color: var(--mountain-mist);
}
