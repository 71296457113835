.units-drop-down,
.units-drop-down-1 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  position: relative;
  width: 60%;
}
