.hero-swiper {
  align-items: flex-start;
  display: flex;
  margin-top: 10px;
  min-width: 100%;
  z-index: 1;
}

.overlap-group {
  height: 500px;
  position: relative;
  width: 100%;
}

.hero-swiper-images {
  display: block;
  height: 500px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
}

.swiper-1 {
  align-items: center;

  /* background-image: url(../../static/img/swiper-1@1x.png); */
  background-position: 50% 10% !important;
  background-size: cover !important;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  position: relative;
  width: 100%;
  transition: 0.5s;
}
.swiper-blent {
  background-color: #000;
  background-blend-mode: multiply;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  opacity: 0.5;
}

.bg-video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #e0e0e0;
}

.bg-video {
  position: absolute;
  top: 0%;
  left: 0%;
  /* transform: translate(-50%, -50%); */

  /* object-fit: fill; */
  height: 100%;
  width: 100%;
  /* new */
  background-color: white;
}

.swiper-1 .slide-volume-control {
  position: absolute;
  bottom: 5%;
  right: 20%;
  cursor: pointer;

  z-index: 10 !important;
}

.slide-volume-control svg {
  color: black;
}

.text {
  position: absolute;
  top: 30%;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 80%;
}

.title {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 28px;
  margin-top: -1px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.we-are-your-best-fri {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  width: 45%;
}

.indicators {
  align-items: center;
  display: flex;
  gap: 10px;
  left: 48%;
  position: absolute;
  bottom: 5%;
  width: fit-content;
}

.indicator-1 {
  background-color: var(--blue);
  border-radius: 6px;
  height: 12px;
  min-width: 12px;
}

.indicator {
  background-color: var(--alto);
  border-radius: 6px;
  height: 12px;
  min-width: 12px;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 1.7rem;
  }
  .we-are-your-best-fri {
    width: 100%;
  }
  .indicators {
    left: 45%;
  }
  .bg-video {
    object-fit: contain;
 
  }

  .swiper-1 .slide-volume-control {
    position: absolute;
    bottom: -5%;
    right: 2%;
    cursor: pointer;
  
    z-index: 10 !important;
  }
}

.swiper-2 {
  align-items: center;
  background-color: #000;
  background-image: url("../../static/img/home-swiper-3.png");
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  position: relative;
  width: 100%;
  transition: 1s;
}

.main-swiper {
  width: 100%;
  height: 75vh;
  margin-top: 5rem;
}
.background-tint {
  /* background-color: rgba(153, 152, 151, 0.5); */
  background-color: rgb(106, 106, 106);
  background-blend-mode: multiply;
}
.main-swiper > * {
  width: 100%;
  height: 100%;
}

.swiper-btn {
  position: absolute;
  bottom: 20%;
  width: 8%;
  padding: 10px;
}

@media only screen and (max-width: 650px) {
  .main-swiper {
    margin-top: 10%;
    height: 32vh !important;
  }
}
@media only screen and (max-width: 440px) {
  .main-swiper {
    margin-top: 10%;
    /* height: 40vh !important; */
  }
}
.swiper-shimmer {
  height: 75vh;
  position: relative;
  overflow: hidden;
}
.swiper-shimmer::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(to right, #f5f5f5 0%, #e0e0e0 20%, #f5f5f5 40%);
  animation: shimmer 2s infinite;
}
@keyframes shimmer {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.swiper-1 .DotSpinner-module_container__KTuXu {
  top: 50%;
  left: 50%;
  z-index: 1;
}
