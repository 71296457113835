.loading-div {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
}
.my-learning-container {
  overflow-x: hidden;
}
.my-learning {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;

  overflow: hidden;
  position: relative;
  width: 100%;
}

.overlap-group1-2 {
  position: relative;
  width: 100%;
  z-index: 1;
}

.my-learning-title-section-container {
  position: relative;
}

/*Cards Container starts here*/
.my-learning-page .grade-cards-container {
  display: flex;
  align-items: flex-start;
  width: 80%;
  /* height: 100vh; */
  flex-wrap: wrap;
  margin: 1% auto 10% auto;
  gap: 15px;
  overflow-y: overlay;
}

.footer-1 {
  align-items: center;
  background-color: var(--haiti);
  display: flex;
  flex-direction: column;
  height: 400px;
  left: 1px;
  padding: 41px 66px;
  position: absolute;
  top: 890px;
  width: 100%;
}

.flex-row-3 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  gap: 163px;
  height: 170px;
  margin-left: 4px;
  margin-top: 29px;
  min-width: 1192px;
}

.about-schooltube-in-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 170px;
  width: 422px;
}

.logo-1 {
  height: 40px;
  width: 183px;
}

.lorem-ipsum-dolor-si-3 {
  letter-spacing: 0;
  line-height: 20px;
  width: 420px;
}

.contact-us-section-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  width: 326px;
}

.contact-us-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 2.65px;
  min-height: 30px;
}

.phone-number-2 {
  align-items: center;
  display: flex;
  gap: 14px;
  margin-top: 20px;
  min-width: 237px;
  padding: 0 0px;
}

.phone-1-1 {
  height: 19px;
  width: 18px;
}

.address-21 {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 203px;
  white-space: nowrap;
}

.email-1 {
  align-items: center;
  display: flex;
  gap: 15px;
  margin-left: 0.5px;
  margin-top: 10px;
  min-width: 326px;
  padding: 0 0px;
}

.letter-1-1 {
  height: 16px;
  width: 17px;
}

.schooltubeonlinehotmailcom-1 {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 292px;
  white-space: nowrap;
}

.follow-us-section-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 70px;
  width: 117px;
}

.follow-us-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 2px;
  min-height: 30px;
  min-width: 115px;
}

.social-media-icons-1 {
  height: 30px;
  margin-right: 2px;
  width: 80px;
}

.scroll-top-btn-1 {
  align-self: flex-end;
  cursor: pointer;
  height: 58px;
  margin-top: 6px;
  width: 58px;
}

.line-1-1 {
  height: 1px;
  margin-top: 15px;
  object-fit: cover;
  width: 1300px;
}

.flex-row-4 {
  align-items: flex-start;
  display: flex;
  gap: 782px;
  margin-top: 19px;
  min-width: 1300px;
}

.copyright-2022-schoo-1 {
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  width: 356px;
}

.powered-by-brainkets-1 {
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  width: 162px;
}

.my-learning-1 {
  height: 30px;
  left: 181px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: 64px;
  width: 155px;
}

.my-learnin-course-1-card {
  align-items: flex-start;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0px 0px 8px #00000040;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 250px;
  left: 181px;
  overflow: hidden;
  padding: 5.4px 5.7px;
  position: absolute;
  top: 140px;
  width: 200px;
}

.grade-9-english {
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 9.29px;
  margin-top: 9px;
  text-shadow: 0px 3px 2px #0000004c;
  white-space: nowrap;
}

.redeem-container {
  border-radius: 60px;
  height: 50px;
  right: 10%;
  position: absolute;
  top: 50px;
  width: 360px;
}

.redeem-text-field {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--mist-gray);
  border-radius: 60px;
  display: flex;
  gap: 10px;
  height: 50px;
  left: 0;
  overflow: hidden;
  padding: 14px 20px;
  position: absolute;
  top: 0;
  width: 360px;
}

.ticket-icon {
  align-self: center;
  height: 12px;
  width: 14px;
}

.enter-redeem-code {
  color: var(--mist-gray);
  font-family: var(--font-family-poppins);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  min-height: 20px;
  min-width: 130px;
}

.redeem-btn {
  align-items: flex-start;
  background-color: var(--blue);
  border-radius: 0px 60px 60px 0px;
  display: flex;
  height: 50px;
  left: 260px;
  overflow: hidden;
  padding: 12.9px 22.5px;
  position: absolute;
  top: 0;
  width: 100px;
}
.redeam-code-section button {
  white-space: nowrap;
}

.redeem {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  min-height: 22px;
  width: 55px;
}

.nav-bar-1 {
  align-items: center;
  background-color: var(--white);
  box-shadow: 0px 2px 6px #0000001a;
  display: flex;
  height: 80px;
  left: 0;
  padding: 0 70px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

.logo-2 {
  cursor: pointer;
  height: 40px;
  width: 183px;
}

@media only screen and (max-width: 650px) {
  .redeem-container {
    top: 55px;
    right: 2%;
  }
  .title-line {
    width: 80%;
    left: 2%;
  }
  .my-learning-1 {
    top: 64px;
    left: 2%;
  }
  .my-learning-page .grade-cards-container {
    display: flex;
    width: 100% !important;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 5%;
    margin-left: 10px;
    gap: 15px;
    /* height: 170vh; */
    overflow-y: scroll;
  }
  .my-learning-title-section {
    margin: 30% auto 0 !important;
    width: 90% !important;
  }
  .my-learning-page .title-redeam-section {
    flex-direction: column-reverse !important;
    align-items: flex-start !important;
  }
  .title-redeam-section .poppins-semi-bold-black-25px {
    font-size: 1rem;
  }
  .redeam-code-section button {
    font-size: 12px;
    white-space: normal;
  }
  .redeam-code-section {
    width: 100% !important;
  }
  .teacher-line-33,
  .line-33-1 {
    width: 30% !important;
  }
  .redeam-code-section input::placeholder {
    color: gray;
    font-size: 14px;
    font-family: Arial, sans-serif;
  }
  .redeam-code-section button {
    width: 30% !important;
  }
}

.no-grades-available-container {
  height: 70vh;
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.no-grades-available-container button {
  width: 20%;
  margin: 5% auto;
}

.my-learning-title-section {
  position: relative;
  width: 80%;
  margin: 7% auto 0;
}
.title-redeam-section {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.redeam-code-section {
  width: 25%;
}

.redeam-code-section button {
  width: 40%;
  border-radius: 20px;
}
.myLearning-title-lines {
  width: 100%;
}

/*for time spent */
.time-spent-wrap {
  display: inline-block;
  position: relative;
}

.time-spent-content h5,
.time-spent-wrap {
  position: absolute;
  bottom: 0%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  color: #3434ff;
}

.time-spent-wrap .time-spent-text {
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  font-size: 16px;
  font-weight: 600;
  color: #3e97fc;
  text-align: center;
  display: inline-block;
  width: 200px;
  padding: 10px;
  z-index: 10;
  border: 2px solid transparent;
  border-bottom-color: #3e97fc;
  transition: border-bottom 0.5s 0.7s ease, border-left 0.3s 0.5s ease,
    border-top 0.7s 0.5s ease, border-right 0.9s 0.5s ease;
}

.time-spent-wrap:hover .time-spent-text {
  text-align: center;
  border-color: transparent;
  border-bottom-color: transparent;
}

.time-spent-wrap .time-spent-box {
  position: relative;
  overflow: hidden;
  width: 220px;
  height: 0;
  margin-top: -3px;
  border: 2px solid transparent;
  border-bottom-color: transparent;
  transition: height 0.5s ease, border-right 0.2s 0.7s ease,
    border-bottom 0.6s 0.7s ease, border-left 0.9s 0.7s ease;
}

.time-spent-wrap:hover .time-spent-box {
  height: 150px;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.time-spent-content h5 {
  /* text-transform: uppercase; */
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 1rem;
}
.time-spent-content h5 b {
  font-weight: 650;
}

.time-spent-wrap .time-spent-content {
  opacity: 0;
  color: #3e97fc;
  z-index: 1;
  transition: opacity 1s 0.4s ease;
}

.time-spent-wrap:hover .time-spent-content {
  opacity: 1;
}
.time-spent-content h5:after,
.time-spent-content h5:before {
  position: absolute;
  content: " ";
  border-top: 1px solid #3e97fc;
  width: 0px;
  bottom: 8px;
  transition: width 0.4s 1.5s ease;
}
.time-spent-content h5:after {
  right: -20px;
}

.time-spent-content h5:before {
  left: -25px;
}

.time-spent-wrap:hover h5:after,
.time-spent-wrap:hover h5:before {
  width: 15px;
}

/* new */
.custom-number-input {
  /* Hide the default up and down arrows */
  -moz-appearance: textfield;
  appearance: textfield;
}

.custom-number-input::-webkit-inner-spin-button,
.custom-number-input::-webkit-outer-spin-button {
  /* Hide the webkit default arrows */
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
