.counters-component {
  align-items: center;
  display: flex;
  gap: 200px;
  height: fit-content;
  margin-top: 100px;
  min-width: 1434px;
  padding: 0 205px;
  z-index: 5;
}

@media only screen and (max-width: 600px) {
  .counters-component {
    flex-direction: column;
    gap: 75px;
    height: fit-content;
    margin-top: 40px;
    /* min-width: 1434px; */
    /* padding: 0 205px; */
    padding: 0;
    display: block;
    min-width: auto;
  }
  .frame-20 {
    float: left;
    gap: 2px !important;
    width: 115px !important;
  }
  .frame-18 {
    height: 55px !important;
    min-width: 55px !important;
    width: 55px !important;
  }
  .frame-203 {
    gap: 0px !important;
  }

  .students,
  .materials,
  .videos {
    font-size: 10px;
  }
  .frame-202 {
    height: 16px !important;
  }
  .frame-202-1 {
    height: 16px !important;
  }
  .frame-202-2 {
    height: 16px !important;
  }
}
@media only screen and (max-width: 350px) {
  .counters-component {
    flex-direction: column;
    gap: 75px;
    height: fit-content;
    margin-top: 40px;
    /* min-width: 1434px; */
    /* padding: 0 205px; */
    padding: 0;
    display: block;
    min-width: auto;
  }
  .frame-20 {
    float: left;
    gap: 2px !important;
    width: 100px !important;
  }
  .frame-18 {
    height: 44px !important;
    min-width: 44px !important;
    width: 44px !important;
  }
  .frame-203 {
    gap: 0px !important;
  }

  .students,
  .materials,
  .videos {
    font-size: 10px;
  }
  .frame-202 {
    height: 16px !important;
  }
  .frame-202-1 {
    height: 16px !important;
  }
  .frame-202-2 {
    height: 16px !important;
  }
}

.frame-20 {
  align-items: center;
  display: flex;
  gap: 26px;
  width: 208px;
}

.frame-18 {
  height: 83px;
  min-width: 83px;
}

.frame-203 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: fit-content;
}

.frame-202 {
  height: 30px;
  min-width: 90px;
  overflow: hidden;
  position: relative;
}

.text-1 {
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -1px;
}

.students,
.materials,
.videos {
  letter-spacing: 0;
  line-height: normal;
  width: fit-content;
}

.frame-202-1 {
  height: 30px;
  min-width: 99px;
  overflow: hidden;
  position: relative;
}

.frame-202-2 {
  height: 30px;
  min-width: 88px;
  overflow: hidden;
  position: relative;
}

.text-3 {
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: -2px;
}

/*design the counter*/
.count-enter {
  opacity: 0;
  transform: translateY(10px);
}

.count-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 1000ms ease-out;
}
