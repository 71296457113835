.frame-299-1 {
  align-items: center;
  display: flex;
  gap: 15px;
  position: relative;
  width: fit-content;
}

.grade-7 {
  height: 30px;
  letter-spacing: 0;
  line-height: 27px;
  margin-top: -1.50px;
  white-space: nowrap;
  width: 217.5px;
}

.frame-299-1.frame-299 {
  left: 0;
  position: absolute;
  top: 0;
}

