.how-it-works-section-with-bg {
  align-items: flex-start;
  /* background-color: var(--river-bed-color); */
  background-color: #d4e4fe;

  display: flex;
  height: fit-content;
  width: 100%;
  z-index: 9;
  overflow-x: hidden;
}

.overlap-group2 {
  align-items: center;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  min-height: 640px;
  padding: 120px 0;
  width: 100%;
}

.how-it-works-title-and-lines {
  align-items: center;
  display: flex;
  gap: 15px;
  margin-left: 1px;
  min-width: 354px;
  justify-content: center;
}

.line-31-stroke-4 {
  height: 3px;
  width: 40px;
}

.how-it-works {
  height: 40px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  color: black;
}

.here-you-can-know-how-school-tube-works {
  /* color: var(--white); */
  color: black;
  font-family: var(--font-family-poppins);
  font-size: 20px;
  font-weight: 400;

  letter-spacing: 0;
  line-height: 25px;
  margin-top: 15px;
  text-align: center;
}

.step-container-1 {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;

  gap: 94px;
  height: fit-content;
  margin-top: 80px;
  position: relative;
  width: 80%;
}

.step-container {
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 94px;
  width: 100%;
  height: fit-content;
  margin-top: 40px;
  min-width: 1080px;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .how-it-works {
    height: 100% !important;
    line-height: 38px !important;
  }
  .step-container-1 {
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;
  }
  .step-container {
    flex-direction: column;
    gap: 30px;
  }
}
