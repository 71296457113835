.step-1 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  width: 45%;
}

.icon-check_mark {
  height: 20px;
  min-width: 25.92px;
}

.text-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  width: fit-content;
}

.x1-buy-school-tube-cards {
  letter-spacing: 0;
  margin-top: -1px;
  width: 100%;
  color: #1832c1;
}

.buy-our-cards-from-o {
  letter-spacing: 0;
  line-height: 30px;
  width: 100%;
  color: black;
}

.step-1.step-2 .icon-check_mark,
.step-1.step-4 .icon-check_mark,
.step-1.step .icon-check_mark {
  min-width: 25.92px;
}

.step-1.step-2 .x1-buy-school-tube-cards,
.step-1.step-4 .x1-buy-school-tube-cards,
.step-1.step .x1-buy-school-tube-cards {
  width: 456.95px;
}

.step-1.step-2 .buy-our-cards-from-o,
.step-1.step-3 .buy-our-cards-from-o,
.step-1.step-4 .buy-our-cards-from-o,
.step-1.step-5 .buy-our-cards-from-o,
.step-1.step .buy-our-cards-from-o {
  white-space: wrap;
}

.step-1.step-3 .x1-buy-school-tube-cards,
.step-1.step-5 .x1-buy-school-tube-cards {
  width: 456.95px;
}
