.heart {
  width: 100px;
  height: 100px;
  /* position: absolute; */
  /* right: -5%; */
  /* top: 50%; */
  /* transform: translate(-50%, -50%); */
  background: url(https://cssanimation.rocks/images/posts/steps/heart.png)
    no-repeat;
  background-position: 0 0;
  cursor: pointer;
  animation: fave-heart 1s steps(28);
}
/* .heart:hover {
  background-position: -2800px 0;
  transition: background 1s steps(28);
} */
@keyframes fave-heart {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -2800px 0;
  }
}
