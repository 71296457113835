.supported-grades-cards-wrapper {
  overflow: hidden;
  border-radius: 10px;
  width: 310px;
}
.supported-grades-swiper .supported-grades-card-1 {
  align-items: flex-start;
  background-color: transparent;
  /* border-radius: 8px; */
  display: flex;
  flex-direction: column;
  height: 400px;
  position: relative;
  width: 100%;
  margin-right: 21px;
  overflow: hidden;

  /* border: 4px solid transparent;
  border-image: linear-gradient(to right, #4444ff, #ff3d3d) 1; */
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(45deg, #304ffe, #e42129) border-box;
  border: 2px solid transparent;
  border-radius: 15px;
  box-shadow: 0 0 11.94px #00000033;
}
/* .supported-grades-swiper .supported-grades-card-1::before {
  content: "";
  position: absolute;

  width: 700px;
  background-image: linear-gradient(180deg, red, blue);
  height: 150%;
  animation: rotBGimg 3s linear infinite;
  transition: all 0.2s linear;
}
@keyframes rotBGimg {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.supported-grades-swiper .supported-grades-card-1::after {
  content: "";
  position: absolute;

  inset: 5px;
  border-radius: 15px;
  z-index: -1;
} */
.supported-grades-swiper .card-content-container {
  z-index: 10;
  background: #fff;
  width: 100%;
}

.supported-grades-card-1-blent {
  /* background-color: #000; */
  /* background-blend-mode: multiply; */
  /* new */
  min-height: 130px;
}

.grade-1 {
  letter-spacing: 0;
  line-height: 18px;
  margin-left: 20px;
  margin-top: 19px;
  white-space: pre-wrap;
}

.language-for-grade {
  align-items: flex-start;
  display: flex;
  gap: 11px;
  margin-left: 20px;
  margin-top: 24px;
  min-width: 104px;
}

.group-5 {
  height: 20px;
  margin-top: 0;
  width: 18px;
}

.language-6 {
  letter-spacing: 0;
  line-height: 20px;
  min-height: 18px;
  white-space: nowrap;
}

.sup-material-slider {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}
.sup-materials-container::-webkit-scrollbar {
  width: 2px;
}

/* Firefox */
.sup-materials-container scrollbar {
  width: 1px;
}
.sup-material-slider .sup-materials-container {
  width: 100%;
  height: 100%;
  /* display: flex; */
  flex-direction: row;
  gap: 10px;
  overflow-x: scroll;
}
.sup-materials-container .swiper {
  padding: 0px !important;
}
.sup-material-item {
  background-color: #304ffe1a;
  color: #304ffe;
  width: fit-content;
  padding: 10px;
  white-space: nowrap;
  border-radius: 10px;
  font-weight: 500;
  font-family: "Poppins";
  font-size: 0.8rem;
}
.sup-materials-container .sup-material-swiper-slider {
  width: fit-content !important;
}

@media only screen and (max-width: 650px) {
  .sup-material-item {
    padding: 5px 10px;
    display: flex;
    align-items: center;
  }
  .sup-materials-container .sup-material-swiper-slider {
    width: fit-content !important;
  }
}
