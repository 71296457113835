.about-us-container {
  width: 100%;
  /* margin: 5% auto 2% auto; */
  margin: 2% auto 2% auto;
}
.about-us-content {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 2%;
  gap: 20px;
}
.about-us-content .about-us-header-center {
  text-align: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 2%;
}

.about-us-content p {
  font-size: 1.2rem;
  font-family: "Poppins";
  font-weight: 400;
  padding: 0 10px;
}

.about-us-container img {
  width: 100%;
}

.about-us-container .about-us-together {
  font-family: "Poppins";
  width: 30%;
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
}
.about-us-container .about-us-flex-row {
  display: flex;
  flex-direction: row;
  gap: 10%;
}

.about-us-body pre {
  overflow: hidden !important;
  font-size: 1em !important;
}

@media only screen and (max-width: 650px) {
  .about-us-content {
    width: 100% !important;
  }
  .about-us-container .about-us-flex-row {
    align-items: center;
  }
  .about-us-container .about-us-together {
    font-size: 1.5rem;
  }
}
