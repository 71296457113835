.title-lines,
.title-lines-1 {
  align-items: flex-start;
  background-image: url(../../static/img/line-32@1x.svg);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 1px;
  left: 181px;
  width: 80%;
  position: absolute;
  top: 114px;
}

@media only screen and (max-width: 650px) {
  .title-lines {
    left: 2%;
  }
}

.teacher-line-33,
.line-33-1 {
  border: 2px solid #304ffe;
  height: 3px;
  margin-top: -3px;
  width: 170px;
}

.teachers-title-lines {
  align-items: flex-start;
  background-image: url(../../static/img/line-32@1x.svg);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 1px;

  width: 100%;
  position: absolute;
  bottom: 0%;
}
