.register-word {
  background-color: transparent;
  color: var(--blue);
}
.captcha-text {
  background-color: #ebecec;
  text-align: center;
  padding: 20px;
  letter-spacing: 20px;
  font-size: 1.5rem;
  color: #fff;
}
@media only screen and (min-width: 576px) {
  .modal-dialog {
    max-width: 60% !important;
    margin-right: auto;
    margin-left: auto;
  }
}
