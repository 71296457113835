:root {
  --black: #000000;
  --black-squeeze: #f3f7fa;
  --blue: #304ffe;
  --mercury: #e4e4e4;
  --mist-gray: #c4c4c4;
  --mountain-mist: #999999;
  --steel-gray: #1f1f39;
  --white: #ffffff;

  --font-size-l: 30px;
  --font-size-m: 25px;
  --font-size-s: 18px;
  --font-size-xs: 15px;
  --font-size-xxs: 14px;
  --font-size-xxxs: 13px;
  --font-size-xxxxs: 12px;

  --font-family-inter-semibold: "Inter-SemiBold", Helvetica;
  --font-family-poppins: "Poppins", Helvetica;
  --font-family-poppins-bold: "Poppins-Bold", Helvetica;
  --font-family-poppins-extrabold: "Poppins-ExtraBold", Helvetica;
  --font-family-roboto-bold: "Roboto-Bold", Helvetica;
  --font-family-roboto-light: "Roboto-Light", Helvetica;
  --font-family-roboto-regular: "Roboto-Regular", Helvetica;
  --font-family-roboto-semibold: "Roboto-SemiBold", Helvetica;
}
.poppins-medium-blue-14px {
  color: var(--blue);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-steel-gray-22px {
  color: var(--steel-gray);
  font-family: var(--font-family-poppins);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-black-13px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxs);
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-mountain-mist-12px {
  color: var(--mountain-mist);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxxs);
  font-style: normal;
  font-weight: 500;
}

.roboto-bold-blue-14px {
  color: var(--blue);
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 700;
}

.poppins-extra-extra-bold-white-20px {
  /* color: var(--white); */
  color: black;
  font-family: var(--font-family-poppins-extrabold);
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
}

.poppins-normal-white-18px {
  /* color: var(--white); */
  color: black;
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.roboto-light-white-14px {
  /* color: var(--white); */
  color: black;
  font-family: var(--font-family-roboto-light);
  font-size: var(--font-size-xxs);
  font-style: normal;
  font-weight: 300;
}

.poppins-semi-bold-blue-24px {
  color: var(--blue);
  font-family: var(--font-family-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.poppins-normal-black-13px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxs);
  font-style: normal;
  font-weight: 400;
}

.poppins-medium-white-16px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-blue-13px {
  color: var(--blue);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxs);
  font-style: normal;
  font-weight: 500;
}

.poppins-normal-black-25px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.poppins-semi-bold-white-20px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-black-18px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 600;
}
