.unlock-power-of-study-section {
  align-items: center;
  justify-content: center;
  background-color: var(--hawkes-blue);
  display: flex;
  height: 100px;
  padding: 0 10px;
  position: relative;
  width: 100%;
  z-index: 8;
}

.group-148 {
  align-self: flex-end;
  height: 82px;
  margin-left: 10px;
  width: 78px;
}

.unlock-the-power-of-your-study {
  height: 100%;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 2px;
  margin-left: 192px;
  width: 472px;
}

.group-147 {
  align-self: flex-end;
  height: 95px;
  margin-left: 198px;
  width: 82px;
}

.unlock-power-of-study-section.unlock-power-of-study-section-1,
.unlock-power-of-study-section.unlock-power-of-study-section-2 {
  left: 1px;
  position: absolute;
  top: 790px;
  z-index: unset;
}

@media only screen and (max-width: 600px) {
  .group-148 {
    display: none;
  }
  .group-147 {
    display: none;
  }
  .unlock-the-power-of-your-study {
    width: 100%;
    margin-left: 0;
    margin-top: 7%;
    line-height: 100%;
  }
  .valign-text-middle {
    flex-direction: row;
    justify-content: space-between;
  }
  .read-more-btn-2 {
    margin-left: 0;
  }
}
