.ask-a-question {
  align-items: flex-start;
  border: 1px none;
  display: flex;
  height: 1024px;
  padding: 190px 400px;
  width: 100%;
}

.ask-question-popup {
  align-items: center;
  background-color: var(--white);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  height: 640px;
  overflow: hidden;
  padding: 39px 0;
  width: 640px;
}

.ask-your-question {
  align-self: flex-start;
  height: 30px;
  letter-spacing: 0;
  line-height: 27px;
  margin-left: 50px;
  white-space: nowrap;
}

.notes {
  align-items: flex-start;
  border: 1.5px solid;
  border-color: var(--mountain-mist);
  border-radius: 15px;
  display: flex;
  height: 230px;
  margin-top: 31px;
  overflow: hidden;
  padding: 18.5px 30px;
  width: 540px;
}

.write-your-question {
  height: 30px;
  letter-spacing: 0;
  line-height: 27px;
  white-space: nowrap;
}

.upload-image {
  background-color: var(--white);
  border: 1px dashed;
  border-color: var(--mountain-mist);
  border-radius: 10px;
  height: 100px;
  margin-top: 30px;
  overflow: hidden;
  position: relative;
  width: 540px;
}

.image-icon {
  height: 35px;
  left: 248px;
  position: absolute;
  top: 24px;
  width: 45px;
}

.upload-image-1 {
  color: var(--mountain-mist);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-weight: 400;
  left: 220px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: 57px;
  white-space: nowrap;
}

.cancel-and-submit-btns {
  align-items: flex-start;
  display: flex;
  gap: 30px;
  margin-top: 80px;
  min-width: 540px;
  position: relative;
}

.submit-question {
  align-items: flex-start;
  background-color: var(--blue);
  border-radius: 90px;
  cursor: pointer;
  display: flex;
  height: 60px;
  overflow: hidden;
  padding: 10.5px 23.4px;
  width: 255px;
}

.submit-question-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 36px;
  min-width: 207px;
  text-align: center;
}

.ask-question-modal {
  width: 100%;
}
.ask-question-modal .modal-dialog {
  width: 30%;
}
.lkjDVC {
  max-width: 100% !important;
  height: 100% !important;
}
.ask-your-question-btn {
  width: 45%;
  border-radius: 20px;
}
.ask-question-modal-footer {
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}

@media only screen and (max-width: 650px) {
  .ask-question-modal .modal-dialog {
    width: 100% !important;
  }
}
