.details-group-2,
.details-group-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 20px;
  margin-top: 21px;
  width: fit-content;
}

.number-of-materials-2,
.number-of-materials-3 {
  height: 20px;
  margin-right: -2px;
  min-width: 116.62px;
  position: relative;
}

.group-26,
.group-27 {
  height: 15px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 16px;
}

.address-29,
.address-30 {
  left: 31px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;

  top: 0;
  white-space: nowrap;
}

.number-of-videos-2,
.number-of-videos-3 {
  height: 20px;
  min-width: 108.62px;
  position: relative;
}

.group-24,
.group-25 {
  height: 15px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 16px;
}

.number-of-pdf-2,
.number-of-pdf-3 {
  height: 20px;
  min-width: 87.62px;
  position: relative;
}

.number-of-quizes-2,
.number-of-quizes-3 {
  height: 20px;
  min-width: 104.62px;
  position: relative;
}

.quiz-icon-1-14,
.quiz-icon-1-15 {
  height: 16px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 16px;
}
