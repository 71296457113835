.frame-2 {
  align-items: center;
  background-color: var(--blue-2);
  border-radius: 6px;
  display: flex;
  gap: 12px;
  justify-content: center;
  padding: 3.6px 12px;
  width: fit-content;
}

.language-7,
.biology,
.language-8,
.biology_2,
.language-9,
.biology_3,
.language-10,
.biology_4,
.language-11,
.biology_5 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.20px;
  width: fit-content;
}

