.edit-profile-form-container {
  width: 80%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5% auto;
  gap: 30px;
}

.edit-profile-form-container .input-row {
  width: 100%;
  display: flex;
  gap: 5px;
}
.edit-profile-form-container .input-col {
  width: 50%;
}

.edit-profile--form-btn {
  width: 60%;
  margin: auto;
}
.edit-profile--form-btn button {
  border-radius: 20px;
  padding: 10px;
  font-size: 1.2rem;
  margin-bottom: 5%;
}

/* new */
.editProfile .countryDrop .dropdown-menu.show {
  height: auto !important;
}
