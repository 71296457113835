.read-more-btn-2 {
  align-items: center;
  background-color: var(--blue);
  border-radius: 10px;
  display: flex;
  gap: 10px;
  height: 40px;
  justify-content: center;
  margin-left: 216px;
  padding: 4px 10px;
  width: fit-content;
}
@media only screen and (max-width: 600px) {
  .read-more-btn-2 {
    margin-left: 10%;
  }
}

.read-more-1 {
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.read-more-btn-2.read-more-btn-4,
.read-more-btn-2.read-more-btn-6,
.read-more-btn-2.read-more-btn-7,
.read-more-btn-2.read-more-btn-8 {
  margin-bottom: 4.96px;
}
