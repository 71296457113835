@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,500,600,800|Roboto:400,700,300,500,600|Inter:600");
/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url("https://px.animaapp.com/6266a61fa0aef730b1d7c23b.6266a621de68953c78e2f85b.bcErNKi.rcp.png");

:root {
  margin: 0;
  padding: 0;
}
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.screen a {
  display: contents;
  text-decoration: none;
}
a {
  text-decoration: none;
}
.container-center-horizontal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.align-self-flex-end {
  align-self: flex-end;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

* {
  box-sizing: border-box;
}
