.inside-grade {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  height: 1340px;
  position: relative;
  width: 100%;
}

.overlap-group-5 {
  height: 1260px;
  margin-top: 80px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.units-and-footer-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 226px;
  left: 0;
  position: absolute;
  top: 310px;
  width: 100%;
}

.footer-and-unlock-the-power-section-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.footer-4 {
  background-color: var(--haiti);
  height: 400px;
  min-width: 100%;
}

.flex-col-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 70px;
  min-height: 289px;
  position: relative;
  top: 70px;
  width: 1304px;
}

.flex-row-13 {
  align-items: flex-start;
  display: flex;
  gap: 163px;
  height: 170px;
  min-width: 1192px;
}

.about-schooltube-in-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 170px;
  width: 422px;
}

.logo-7 {
  height: 40px;
  width: 183px;
}

.lorem-ipsum-dolor-si-6 {
  letter-spacing: 0;
  line-height: 20px;
  width: 420px;
}

.contact-us-section-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  width: 326px;
}

.contact-us-4 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 2.65px;
  min-height: 30px;
}

.phone-number-5 {
  align-items: center;
  display: flex;
  gap: 14px;
  margin-top: 20px;
  min-width: 237px;
}

.phone-1-4 {
  height: 19px;
  width: 18px;
}

.address-32 {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 203px;
  white-space: nowrap;
}

.email-4 {
  align-items: center;
  display: flex;
  gap: 15px;
  margin-left: 0.5px;
  margin-top: 10px;
  min-width: 326px;
}

.letter-1-4 {
  height: 16px;
  width: 17px;
}

.schooltubeonlinehotmailcom-4 {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 292px;
  white-space: nowrap;
}

.follow-us-section-4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 70px;
  width: 117px;
}

.follow-us-4 {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 2px;
  min-height: 30px;
  min-width: 115px;
}

.social-media-icons-4 {
  height: 30px;
  margin-right: 2px;
  width: 80px;
}

.scroll-top-btn-4 {
  align-self: flex-end;
  cursor: pointer;
  height: 58px;
  margin-top: 6px;
  width: 58px;
}

.line-1-4 {
  height: 1px;
  margin-top: 15px;
  object-fit: cover;
  width: 1300px;
}

.flex-row-14 {
  align-items: flex-start;
  display: flex;
  gap: 782px;
  margin-top: 19px;
  min-width: 1300px;
}

.copyright-2022-schoo-4 {
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  width: 356px;
}

.powered-by-brainkets-4 {
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  width: 162px;
}

.inside-grade-top-banner-1 {
  align-items: flex-start;
  background-color: var(--black-4);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 280px;
  left: 0;
  padding: 49px 180px;
  position: absolute;
  top: 0;
  width: 100%;
}

.grade-9-english-5 {
  height: 40px;
  letter-spacing: 0;
  line-height: 18px;
  width: 356px;
  margin-bottom: 3%;
}

.material-btn-container-2 {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  width: 55%;
  flex-wrap: wrap;
  min-width: 349px;
}

.chemisty-material-btn-1 {
  align-items: center;
  background-color: var(--blue);
  border-radius: 7.5px;
  display: flex;
  gap: 15px;
  height: 30px;
  justify-content: center;
  padding: 4.5px 15px;
  width: fit-content;
}

.chemistry-2,
.math-2,
.physics-2 {
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -0.5px;
  margin-top: -3.5px;
  width: fit-content;
  background: white;
  border-radius: 10px;
  padding: 5px 15px;
}
.active-lesson-tag {
  background-color: var(--blue);
  color: white;
}
.x-material-btn-1 {
  align-items: center;
  background-color: var(--white);
  border-radius: 7.5px;
  display: flex;
  gap: 15px;
  height: 30px;
  justify-content: center;
  padding: 4.5px 15px;
  width: fit-content;
}

.material-btn-container-3 {
  align-items: flex-start;
  display: flex;
  gap: 20px;
  height: 30px;
  margin-top: 20px;
  min-width: 220px;
  position: relative;
}

.grade-details-1 {
  align-items: flex-start;
  background-color: var(--white);
  box-shadow: 0px 0px 10px #00000040;
  display: flex;
  flex-direction: column;
  height: 450px;
  right: 15%;
  position: absolute;
  top: 110px;
  width: 300px;
}

.grade-8-english-title-1 {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 20px;
  margin-top: 19px;
  white-space: nowrap;
}

.nav-bar-4 {
  align-items: center;
  background-color: var(--white);
  box-shadow: 0px 2px 6px #0000001a;
  display: flex;
  height: 80px;
  left: 0;
  padding: 0 70px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

.logo-8 {
  cursor: pointer;
  height: 40px;
  width: 183px;
}
