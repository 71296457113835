.small-videos-dropdow {
  align-items: center;
  background-color: var(--alto-2);
  display: flex;
  flex-direction: column;
  height: 545px;
  padding: 9.8px 0;
  position: relative;
  width: 360px;
}
.selected {
  background-color: #d3d3d3 !important;
 
}
.frame-155 {
  align-items: flex-end;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  height: 363px;
  padding: 10px 8px;
  position: relative;
  width: 360px;
}

.overlap-group-8 {
  height: 34px;
  position: relative;
  width: 342px;
}

.group-30-2 {
  align-items: center;
  display: flex;
  gap: 258px;
  height: 20px;
  left: 0;
  min-width: 342px;
  position: absolute;
  top: 0;
}

.chapter-1 {
  letter-spacing: 0;
  line-height: 20px;
  min-width: 68px;
  white-space: nowrap;
}

.arrow-down-2-1-2 {
  height: 14px;
  width: 14px;
}

.address-34 {
  left: 0;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-shadow: 0px 3px 2px #0000004c;
  top: 14px;
  white-space: nowrap;
}

.group-63 {
  align-items: center;
  display: flex;
  height: 41px;
  margin-right: 2.22px;
  margin-top: 10px;
  min-width: 325px;
  position: relative;
}

.number-9 {
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 6.98px;
  min-width: 7px;
  text-align: center;
  text-shadow: 0px 3px 2px #0000004c;
  white-space: nowrap;
}

.frame-323 {
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 4px;
  height: 26px;
  margin-bottom: 6.98px;
  margin-left: 13px;
  width: 34px;
}

.group-container {
  align-self: flex-start;
  height: 42px;
  margin-left: 10px;
  margin-top: -1px;
  position: relative;
  width: 107px;
}

.group-29 {
  height: 10px;
  left: 0;
  position: absolute;
  top: 6px;
  width: 10px;
}

.overlap-group-9 {
  height: 42px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 106px;
}

.video-lesson-2-second-line-title {
  left: 13px;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  text-shadow: 0px 3px 2px #0000004c;
  top: 0;
}

.frame-327 {
  align-items: flex-start;
  display: flex;
  gap: 9px;
  left: 0;
  position: absolute;
  top: 22px;
  width: fit-content;
}

.frame-325 {
  align-items: center;
  display: flex;
  gap: 6px;
  width: fit-content;
}

.group-28 {
  height: 9.81px;
  min-width: 8.51px;
}

.x0600-mins,
.x1045-mins,
.address-35 {
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  text-shadow: 0px 3px 2px #0000004c;
  white-space: nowrap;
  width: fit-content;
}

.group-31 {
  height: 14px;
  margin-bottom: 6.98px;
  margin-left: 115px;
  width: 7px;
}

.group-64 {
  align-items: center;
  display: flex;
  margin-right: 2.22px;
  margin-top: 10px;
  min-width: 326px;
  position: relative;
}

.number-8 {
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 3.98px;
  min-width: 8px;
  text-align: center;
  text-shadow: 0px 3px 2px #0000004c;
  white-space: nowrap;
}

.frame-323-1 {
  align-self: flex-start;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 4px;
  height: 26px;
  margin-left: 13px;
  margin-top: 1.01px;
  width: 34px;
}

.overlap-group-10 {
  height: 32px;
  margin-left: 10px;
  position: relative;
  width: 105px;
}

.group-32 {
  height: 10px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 10px;
}

.frame-329 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1px;
  left: 1px;
  min-height: 32px;
  position: absolute;
  top: 0;
  width: 104px;
}

.video-lesson-3 {
  letter-spacing: 0;
  line-height: 12px;
  margin-top: -1px;
  min-width: 75px;
  text-shadow: 0px 3px 2px #0000004c;
  white-space: nowrap;
}

.frame-327-1 {
  align-items: flex-start;
  display: flex;
  gap: 9px;
  position: relative;
  width: fit-content;
}

.group-33 {
  height: 14px;
  margin-bottom: 3.98px;
  margin-left: 117px;
  width: 7px;
}

.group-65 {
  align-items: center;
  display: flex;
  height: 26px;
  margin-right: 2.22px;
  margin-top: 11px;
  min-width: 326px;
  position: relative;
}

.number-10 {
  letter-spacing: 0;
  line-height: 20px;
  min-width: 8px;
  text-align: center;
  text-shadow: 0px 3px 2px #0000004c;
  white-space: nowrap;
}

.group-61 {
  align-items: center;
  display: flex;
  gap: 4px;
  height: 12px;
  margin-left: 11px;
  margin-top: 0;
  min-width: 60px;
}

.group-34 {
  height: 10px;
  margin-bottom: 0;
  width: 10px;
}

.pdf-files {
  letter-spacing: 0;
  line-height: 12px;
  min-width: 43px;
  text-shadow: 0px 3px 2px #0000004c;
  white-space: nowrap;
}

.group-66-2 {
  align-items: center;
  display: flex;
  margin-right: 2.22px;
  margin-top: 15px;
  min-width: 326px;
  position: relative;
}

.group-61-1 {
  height: 32px;
  margin-left: 11px;
  position: relative;
  width: 65px;
}

.quiz {
  left: 15px;
  letter-spacing: 0;
  line-height: 12px;
  position: absolute;
  text-shadow: 0px 3px 2px #0000004c;
  top: 0;
  white-space: nowrap;
}

.frame-325-1 {
  align-items: center;
  display: flex;
  gap: 6px;
  left: 0;
  position: absolute;
  top: 12px;
  width: fit-content;
}

.group-35 {
  height: 9.81px;
  min-width: 9.81px;
}

.group-36 {
  height: 10px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 8px;
}

.overlap-group1-3 {
  height: 34px;
  margin-top: 20px;
  position: relative;
  width: 342px;
}

.group-30-1 {
  align-items: center;
  display: flex;
  gap: 255px;
  height: 20px;
  left: 0;
  min-width: 342px;
  position: absolute;
  top: 0;
}

.chapter {
  letter-spacing: 0;
  line-height: 20px;
  min-width: 71px;
  text-shadow: 0px 3px 2px #0000004c;
  white-space: nowrap;
}

.overlap-group2-1 {
  height: 34px;
  margin-top: 1px;
  position: relative;
  width: 342px;
}

.accord-sub-title {
  color: #343ffe;
}
.acc-chapter-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 0 10px 10px;
  background-color: #fff;
  border-radius: 10px;
}

.acc-chapter-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* gap: 20px; */
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.acc-chapter-container:hover {
  /* background-color: rgba(153, 152, 151, 0.5); */
  background-color: #ccc;
}

.video-title-section {
  margin-top: 2px;
  margin-left: 5px;
  /* width: 180px; */
  padding-left: 10px;
}
.video-title-text {
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 25px;
  width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /* font-size: 1.2rem;
  font-weight: 700;
  line-height: 25px;
  width: 80%; */
}
.video-sub-title-text {
  font-size: 1rem;
  font-weight: 500;
}

.video-details {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.video-detail-item {
  font-size: 0.8rem;
  font-weight: 600;
  margin-top: 5px;
}
.video-detail-item img {
  width: 18%;
  margin-right: 5px;
}
.chapter-items-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chapter-items {
  width: 70%;
}
.chapter-items .chapter-items-image {
  width: 100px;
  height: 80px;
  object-fit: contain;
}
.chapter-items-attach-check {
  width: 20%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}
.chapter-items-attach-check input {
  width: 15px;
}
.chapter-items-attach-check img {
  width: 20%;
  margin-right: 5px;
  /* position: absolute;
  left: 0; */
}

.accordion-body .acc-sub-body {
  padding: 0 !important;
}
.chapter-items-number {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.category-tabs-container .css-19kzrtu {
  padding: 0 !important;
}

.accordion-scroll::-webkit-scrollbar {
  width: 0.2em;
  background-color: #f5f5f5;
}

.accordion-scroll::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.accordion-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #555555;
}

/* .category-tabs-container .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  width: 50% !important; 
  width: auto !important;
} */
.css-1ujnqem-MuiTabs-root .MuiTabs-scrollButtons {
  display: flex !important;
}
/*for the video files */
.modal-files-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 40vh;
  overflow-y: scroll;
  padding: 10px;
}
.modal-file-item {
  display: flex;
  gap: 10px;
  align-items: center;
  background: #ccc;
  padding: 10px;
  border-radius: 10px;
}

@media only screen and (max-width: 650px) {
  .chapter-items .chapter-items-image {
    width: 80px !important;
    height: 60px !important;
  }
}
