.base-checkbox-9 {
  border: 1.5px solid;
  border-color: var(--manatee);
  border-radius: 2px;
  height: 20px;
  margin-bottom: 6.98px;
  margin-left: 10px;
  width: 20px;
}

.base-checkbox-9.base-checkbox-3,
.base-checkbox-9.base-checkbox-6 {
  border: 1.5px solid;
  border-color: var(--manatee);
  border-radius: 2px;
  height: 20px;
  margin-bottom: 3.98px;
  margin-left: 10px;
  width: 20px;
}

.base-checkbox-9.base-checkbox-4 {
  border: 1.5px solid;
  border-color: var(--manatee);
  border-radius: 2px;
  height: 20px;
  margin-left: 178px;
  width: 20px;
}

.base-checkbox-9.base-checkbox-5 {
  border: 1.5px solid;
  border-color: var(--manatee);
  border-radius: 2px;
  height: 20px;
  margin-bottom: 3.98px;
  margin-left: 173px;
  width: 20px;
}

.base-checkbox-9.base-checkbox-7 {
  border: 1.5px solid;
  border-color: var(--manatee);
  border-radius: 2px;
  height: 20px;
  margin-left: 8px;
  width: 20px;
}

.base-checkbox-9.base-checkbox-8 {
  border: 1.5px solid;
  border-color: var(--manatee);
  border-radius: 2px;
  height: 20px;
  margin-bottom: 3.98px;
  margin-left: 8px;
  width: 20px;
}

.base-checkbox-9.base-checkbox-2 {
  border: 2.25px solid;
  border-radius: 3px;
  height: 30px;
  margin-bottom: unset;
  margin-left: unset;
  min-width: 30px;
  width: unset;
}

