.account-setting-container {
  display: flex;
  flex-direction: row;
  width: 90%;
  flex-wrap: nowrap;
  margin: 2% auto;
  margin-bottom: 2%;
  gap: 3%;
  padding: 10px;
}

.account-setting-content {
  width: 100%;
  background: #f3f7fa;
  border-radius: 10px;
  height: 100%;
}

.account-setting-navigation-container {
  display: flex;
  width: 20%;

  height: 100%;
  flex-direction: column;

  gap: 10%;
}

.account-setting-navigation-bg {
  background: #f3f7fa;
  border-radius: 10px;
}
.account-setting-profile-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #f3f7fa;
  border-radius: 10px;
}
.account-setting-profile-section .account-setting-profile {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-top: 10%;
  background-color: #fff;
  border: 3px solid #304ffe;
}
.account-setting-profile-section .account-setting-profile img {
  width: 100%;
  height: 100%;
  /* padding: 5px; */
  border-radius: 50%;
  object-fit: cover;
}

.account-user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.account-user-info .account-user-name {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}
.account-user-info .account-user-phone-number {
  font-size: 1.1rem;
  font-weight: 500;
}
.account-edit-profile-btn {
  margin-bottom: 5%;
  position: relative;
}
.account-edit-profile-btn button {
  background: #304ffe;
  border-radius: 60px;
}
/* .pencil-icon {
  position: absolute;
  top: 10%;
  left: 5%;
} */

.account-setting-nav-section {
  margin-top: 5%;
}
.profile-nav-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 20px;
}
.profile-nav-list a {
  width: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  padding: 10px;
}
.profile-nav-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
  padding: 10px 0;
}
.profile-nav-item img {
  width: fit-content;
}
.profile-nav-item .profile-nav-item-text {
  width: 60%;
  font-size: 20px;
  font-weight: 400;
}

.profile-nav-list a .profile-nav-item:hover,
.profile-nav-item:hover {
  /* background: #303ffe; */
  background: #c3e6f4;
  font-weight: 500;
  border-radius: 10px;
  color: #fff;
}

.activeProfileBtn {
  background: #304ffe !important;
  font-weight: 500 !important;
  border-radius: 10px !important;
  color: #fff !important;
}
.activeProfileBtn:hover {
  background: #304ffe !important;
}

.account-log-out-section {
  margin-top: 5%;
}
.account-log-out-section a {
  text-decoration: none;
}
.profile-logout-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
  padding: 10px 0;
}
.profile-logout-item .profile-icons {
  width: 8%;
}
.profile-logout-item .profile-nav-item-text {
  width: fit-content;
  font-size: 20px;
  font-weight: 400;
  color: #000;
}
.profile-icons {
  height: 50%;
}
.profile-icons > * {
  width: 100%;
  height: 100%;
}
.profile-nav-list .comp-nav-btn {
  background: none;
  border: none;
  width: 100%;
  height: 70px;
  justify-content: center;
  display: flex;
  cursor: pointer;
}
.profile-nav-list .comp-nav-btn:hover {
  background: #c3e6f4;
  font-weight: 500;
  border-radius: 10px;
  color: #fff;
}

.account-setting-title-lines {
  width: 80%;
}

.account-title {
  position: absolute !important;
  bottom: 10px !important;
  left: 0 !important;
  width: fit-content;
  height: 30px;
  letter-spacing: 0;
  line-height: 18px;
}

/*Hover over the profile image */
.account-setting-profile {
  position: relative;
}

.profile-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.account-setting-profile:hover .profile-image-overlay {
  opacity: 1;
}

.fa-camera {
  color: white;
  font-size: 36px;
}

@media only screen and (max-width: 900px) {
  .progress-card-item {
    width: 25% !important;
  }
}
@media only screen and (max-width: 650px) {
  .progress-card-item {
    width: 60% !important;
  }
  .account-setting-container {
    width: 100% !important;
    flex-direction: column !important;
  }
  .account-setting-profile-section {
    width: 100% !important;
  }
  /* .account-setting-profile-section .account-setting-profile {
    width: 30% !important;
  } */
  .account-setting-navigation-container {
    width: 100%;
  }
  .profile-nav-list {
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center;
  }
  .profile-nav-list .comp-nav-btn {
    width: fit-content !important;
  }
  .account-setting-content {
    width: 100% !important;
    margin-top: 20px;
  }
  .progress-cards-container {
    justify-content: center;
  }

  .profile-nav-item .profile-nav-item-text {
    font-size: 15px;
  }
  .profile-logout-item .profile-icons {
    width: 6% !important;
  }
}
@media only screen and (max-width: 1200px) {
  .unlock-the-power-of-your-study {
    white-space: nowrap;
  }
  .account-setting-container {
    width: 100% !important;
    flex-direction: column !important;
  }
  .account-setting-profile-section {
    width: 40%;
    margin: auto;
  }

  .profile-logout-item .profile-icons {
    width: 3%;
  }
  .account-setting-navigation-container {
    width: 100%;
  }
  .profile-nav-list {
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 0 !important;
  }
  .profile-nav-list .comp-nav-btn {
    width: 20%;
  }
  .account-setting-content {
    width: 100% !important;
    margin-top: 20px;
  }
  .progress-cards-container {
    justify-content: center;
  }
  .progress-card-item {
    width: 20%;
  }
  .profile-nav-item .profile-nav-item-text {
    font-size: 15px;
  }
  /* .account-setting-profile-section .account-setting-profile {
    width: 40%;
  } */
  .profile-logout-item {
    gap: 1%;
  }
}
