:root {
  --alto: #d9d9d9;
  --alto-2: #dddddd;
  --black: #000000;
  --black-2: #00000080;
  --black-3: #00000040;
  --black-4: #000000b2;
  --blue: #304ffe;
  --blue-2: #304ffe1a;
  --chicago: #585858;
  --dark-blue: #142dc0;
  --haiti: #120f2d;
  --hawkes-blue: #d4e5ff;
  --manatee: #90909f;
  --mercury: #e4e4e4;
  --mercury-2: #e5e5e5;
  --mist-gray: #c4c4c4;
  --mountain-mist: #999999;
  --river-bed-color: #4a5268;
  --scooter: #2fccfe;
  --slimy-green: #159700;
  --white: #ffffff;

  --font-size-l: 15px;
  --font-size-m: 14px;
  --font-size-s: 13.4px;
  --font-size-xl: 16px;
  --font-size-xs: 11px;
  --font-size-xxl: 18px;
  --font-size-xxxl: 25px;

  --font-family-inter: "Inter", Helvetica;
  --font-family-poppins: "Poppins", Helvetica;
  --font-family-roboto: "Roboto", Helvetica;
}
.poppins-semi-bold-black-14px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.poppins-medium-black-11px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-black-13-4px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-blue-14px {
  color: var(--blue);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.roboto-medium-blue-14-4px {
  color: var(--blue);
  font-family: var(--font-family-roboto);
  font-size: 14.4px;
  font-style: normal;
  font-weight: 500;
}

.poppins-semi-bold-white-14px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.poppins-extra-bold-white-20px {
  /* color: var(--white); */
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
}

.poppins-normal-white-18px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.roboto-light-white-14px {
  color: var(--black);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 300;
}

.poppins-semi-bold-white-25px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-blue-14px {
  color: var(--blue);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-black-18px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-black-25px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-white-35px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
}

.roboto-bold-blue-14px {
  color: var(--blue);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 700;
}

.poppins-semi-bold-white-23-9px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 23.9px;
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-blue-17-9px {
  color: var(--blue);
  font-family: var(--font-family-poppins);
  font-size: 17.9px;
  font-style: normal;
  font-weight: 600;
}

.poppins-medium-black-18px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-mountain-mist-8px {
  color: var(--mountain-mist);
  font-family: var(--font-family-poppins);
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-black-12px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}

.poppins-semi-bold-black-22px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
}

.poppins-medium-black-15px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 500;
}

.poppins-bold-white-18px {
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.poppins-bold-blue-18px {
  color: var(--blue);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.poppins-semi-bold-white-45px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-blue-30px {
  color: var(--blue);
  font-family: var(--font-family-poppins);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-white-20px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.poppins-normal-white-15px {
  color: var(--white) !important;
  font-family: var(--font-family-poppins) !important;
  font-size: var(--font-size-s);
  font-style: normal !important;
  font-weight: 400 !important;
}

.poppins-semi-bold-scooter-22px {
  color: var(--scooter);
  font-family: var(--font-family-poppins);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
}

.poppins-normal-white-14px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.poppins-medium-white-14px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 500;
}

.roboto-medium-mountain-mist-19-6px {
  color: var(--mountain-mist);
  font-family: var(--font-family-roboto);
  font-size: 19.6px;
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-white-10px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-black-10px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.inter-semi-bold-mercury-12px {
  color: var(--mercury);
  font-family: var(--font-family-inter);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-white-16px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 600;
}

.roboto-medium-blue-21px {
  color: var(--blue);
  font-family: var(--font-family-roboto);
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-black-8px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-mist-gray-8px {
  color: var(--mist-gray);
  font-family: var(--font-family-poppins);
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-black-21px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
}

.poppins-semi-bold-black-35px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-mountain-mist-17px {
  color: var(--mountain-mist);
  font-family: var(--font-family-poppins);
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
}

.poppins-bold-white-8px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
}

.poppins-normal-black-16px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.poppins-bold-black-18px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.poppins-bold-chicago-18px {
  color: var(--chicago);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 700;
}

.poppins-semi-bold-chicago-18px {
  color: var(--chicago);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 600;
}

.poppins-medium-mountain-mist-16px {
  color: var(--mountain-mist);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 500;
}

.poppins-medium-mist-gray-10px {
  color: var(--mist-gray);
  font-family: var(--font-family-poppins);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.poppins-semi-bold-blue-22px {
  color: var(--blue);
  font-family: var(--font-family-poppins);
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
}

.poppins-normal-mountain-mist-15px {
  color: var(--mountain-mist);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.poppins-normal-black-18px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.roboto-medium-mountain-mist-21px {
  color: var(--mountain-mist);
  font-family: var(--font-family-roboto);
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
}

.poppins-semi-bold-black-24px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.poppins-normal-mountain-mist-19-5px {
  color: var(--mountain-mist);
  font-family: var(--font-family-poppins);
  font-size: 19.5px;
  font-style: normal;
  font-weight: 400;
}

.poppins-medium-blue-24px {
  color: var(--blue);
  font-family: var(--font-family-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
}

.poppins-bold-white-24px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.roboto-medium-white-21px {
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
}

.poppins-semi-bold-white-40px {
  color: var(--white);
  font-family: var(--font-family-poppins);
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-blue-24px {
  color: var(--blue);
  font-family: var(--font-family-poppins);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-blue-17px {
  color: var(--blue);
  font-family: var(--font-family-poppins);
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
}

.poppins-semi-bold-black-10px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}

.roboto-medium-white-19-6px {
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: 19.6px;
  font-style: normal;
  font-weight: 500;
}

.roboto-medium-blue-19-6px {
  color: var(--blue);
  font-family: var(--font-family-roboto);
  font-size: 19.6px;
  font-style: normal;
  font-weight: 500;
}

.poppins-bold-black-22px {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}
