/* .teachers-details {
  align-items: flex-start;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  height: 1370px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.overlap-group-2 {
  height: 1290px;
  margin-left: -1px;
  margin-top: 80px;
  position: relative;
  width: 1803px;
  z-index: 1;
}

.footer-4 {
  align-items: center;
  background-color: var(--haiti);
  display: flex;
  flex-direction: column;
  height: 400px;
  left: 1px;
  padding: 41px 66px;
  position: absolute;
  top: 890px;
  width: 1440px;
}

.flex-row-9 {
  align-items: flex-start;
  align-self: flex-start;
  display: flex;
  gap: 163px;
  height: 170px;
  margin-left: 4px;
  margin-top: 29px;
  min-width: 1192px;
}

.about-schooltube-in-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 170px;
  width: 422px;
}

.logo-8 {
  height: 40px;
  width: 183px;
}

.lorem-ipsum-dolor-si-4 {
  letter-spacing: 0;
  line-height: 20px;
  width: 420px;
}

.contact-us-section-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 100px;
  width: 326px;
}

.contact-us-4 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 2.65px;
  min-height: 30px;
}

.phone-number-4 {
  align-items: center;
  display: flex;
  gap: 14px;
  margin-top: 20px;
  min-width: 237px;
}

.phone-1-4 {
  height: 19px;
  width: 18px;
}

.address-9 {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 203px;
  white-space: nowrap;
}

.email-4 {
  align-items: center;
  display: flex;
  gap: 15px;
  margin-left: 0.5px;
  margin-top: 10px;
  min-width: 326px;
}

.letter-1-4 {
  height: 16px;
  width: 17px;
}

.schooltubeonlinehotmailcom-4 {
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  min-width: 292px;
  white-space: nowrap;
}

.follow-us-section-4 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 70px;
  width: 117px;
}

.follow-us-4 {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 2.0px;
  min-height: 30px;
  min-width: 115px;
}

.social-media-icons-4 {
  height: 30px;
  margin-right: 2.0px;
  width: 80px;
}

.scroll-top-btn-4 {
  align-self: flex-end;
  cursor: pointer;
  height: 58px;
  margin-top: 6px;
  width: 58px;
}

.line-1-4 {
  height: 1px;
  margin-top: 15px;
  object-fit: cover;
  width: 1300px;
}

.flex-row-10 {
  align-items: flex-start;
  display: flex;
  gap: 782px;
  margin-top: 19px;
  min-width: 1300px;
}

.copyright-2022-schoo-4 {
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  width: 356px;
}

.powered-by-brainkets-4 {
  height: 20px;
  letter-spacing: 0;
  line-height: normal;
  width: 162px;
}

.teacher-details {
  height: 30px;
  left: 181px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  top: 49px;
  width: 198px;
}

.title-lines-3 {
  align-items: flex-start;
  background-image: url(../../static/img/line-32@1x.svg);
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  height: 1px;
  left: 181px;
  min-width: 1080px;
  position: absolute;
  top: 93px;
}

.line-33-3 {
  height: 3px;
  margin-top: -3px;
  width: 198px;
}

.frame-145 {
  background-position: 50% 50%;
  background-size: cover;
  height: 300px;
  left: 181px;
  position: absolute;
  top: 124px;
  width: 1080px;
}

.mr-john-smith-1 {
  height: 30px;
  left: 181px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-shadow: 0px 3px 2px #0000004c;
  top: 453px;
  width: 227px;
}

.surname-2 {
  color: var(--mountain-mist);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-weight: 400;
  left: 181px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-shadow: 0px 3px 2px #0000004c;
  top: 493px;
  white-space: nowrap;
}

.lorem-ipsum-dolor-si-5 {
  color: var(--black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-weight: 400;
  left: 181px;
  letter-spacing: 0;
  line-height: 25px;
  position: absolute;
  text-shadow: 0px 3px 2px #0000004c;
  top: 558px;
  width: 1079px;
}

.wwwyoutubecom-1 {
  color: var(--mountain-mist);
  cursor: pointer;
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-weight: 400;
  left: 181px;
  letter-spacing: 0;
  line-height: 20px;
  position: absolute;
  text-shadow: 0px 3px 2px #0000004c;
  top: 518px;
  white-space: nowrap;
}

.nav-bar-4 {
  align-items: center;
  background-color: var(--white);
  box-shadow: 0px 2px 6px #0000001a;
  display: flex;
  height: 80px;
  left: 0;
  padding: 0 70px;
  position: fixed;
  top: 0;
  width: 1440px;
  z-index: 2;
}

.logo-9 {
  cursor: pointer;
  height: 40px;
  width: 183px;
}
 */
.teachers-details-cards-container {
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  margin: 4% auto;
  gap: 50px;
  padding: 10px;
}

.teacher-details-img {
  width: 100%;
  height: 450px;
  background-color: #304ffe;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
}

.teacher-info {
  display: flex;
  flex-direction: column;
}
.teacher-info h2 {
  color: #304ffe;
}
.teacher-info span {
  color: #999999;
  font-size: 1.3rem;
  font-weight: 500;
}
.teacher-info :last-child {
  font-size: 1.2rem;
}

.teacher-description {
  font-weight: 500;
  font-size: 1.2rem;
  margin-top: 20px;
}
